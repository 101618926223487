import { ActivityIndicator, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import AvatarResultScreen from './AvatarResultScreen';
import { Button } from 'react-native-paper';
import Constants from 'expo-constants';
import GenderSelectionStep from './avatars/GenderSelectionStep';
import ImageUploadStep from './avatars/ImageUploadStep';
import IntroStep from './avatars/IntroStep';
import JobScreen from './JobScreen';
import PaymentScreen from './PaymentScreen';
import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import asyncStorage from '../utils/storage';
import avatarState from '../atoms/avatarState';
import { createStackNavigator } from '@react-navigation/stack';
import { isDesktopWeb } from './PlatformTools';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';

const Stack = createStackNavigator();

function AvatarsScreen({ navigation }) {
    const [user, setUser] = useRecoilState(userState);
    const [avatarProps, setAvatarProps] = useRecoilState(avatarState);


    return (
        <Stack.Navigator
            screenOptions={{
                headerStyle: {
                    backgroundColor: '#F2F2F2',
                    borderBottomWidth: 0,
                },
                headerTitleAlign: 'center',
                headerTitleStyle: { fontSize: scale(5) },
            }}>
            <Stack.Screen name="AvatarIntro" component={IntroStep} options={{
                // title: 'Create magical avatars',
                // headerTitleAlign: 'center',
                // headerTitleStyle: { fontSize: scale(10) },
                title: "Unycorn - avatars",
                headerShown: false,
            }} />
            <Stack.Screen name="Gender" component={GenderSelectionStep}
                options={{
                    title: 'Step 1 of 2',
                    headerTitleStyle: { fontSize: isDesktopWeb ? scale(5) : scale(16), fontWeight: 'normal' },
                }} />
            <Stack.Screen name="Upload Images" component={ImageUploadStep} options={{
                title: 'Step 2 of 2',
                headerTitleStyle: { fontSize: isDesktopWeb ? scale(5) : scale(16), fontWeight: 'normal' },
            }} />
            <Stack.Screen name="PaymentScreen" component={PaymentScreen} options={{
                headerShown: false,
            }} />
            <Stack.Screen name="JobScreen" component={JobScreen} options={{
                headerShown: false,
            }} />
            <Stack.Screen name="AvatarResultScreen" component={AvatarResultScreen} options={{
                headerShown: false,
            }} />
        </Stack.Navigator>
    );
}

const styles = ScaledSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50@mvs',
    },
    credits: {
        marginTop: '20@mvs',
        fontSize: isDesktopWeb ? '10@ms' : '25@ms',
        color: 'grey'
    },
    button: {
        color: 'white',
        fontSize: isDesktopWeb ? '10@mvs' : '20@ms',
        lineHeight: isDesktopWeb ? 'auto' : '20@mvs',
    },
    buttonBox: {
        margin: '10@mvs',
        width: isDesktopWeb ? '100@ms' : '120@ms',
        height: isDesktopWeb ? '20@mvs' : '40@ms',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#282C34',
        borderRadius: '5@mvs',
    },
    buttonLabel: {
        color: 'white',
        fontSize: isDesktopWeb ? '9@ms' : '15@ms',
        lineHeight: isDesktopWeb ? '20@mvs' : '25@mvs'
    },
});


export default AvatarsScreen;