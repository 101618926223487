/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      email
      identityId
      balance
      totalTransactions
      totalGenerations
      totalFailed
      totalPaid
      jobs {
        items {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      email
      identityId
      balance
      totalTransactions
      totalGenerations
      totalFailed
      totalPaid
      jobs {
        items {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      email
      identityId
      balance
      totalTransactions
      totalGenerations
      totalFailed
      totalPaid
      jobs {
        items {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateAIJob = /* GraphQL */ `
  subscription OnCreateAIJob(
    $filter: ModelSubscriptionAIJobFilterInput
    $owner: String
  ) {
    onCreateAIJob(filter: $filter, owner: $owner) {
      id
      userId
      feature
      args
      status
      progress
      currentStep
      totalSteps
      outputLoc
      payload
      info
      createdAt
      user {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      runpodJobs {
        items {
          id
          aiJobId
          userId
          feature
          args
          status
          progress
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAIJob = /* GraphQL */ `
  subscription OnUpdateAIJob(
    $filter: ModelSubscriptionAIJobFilterInput
    $owner: String
  ) {
    onUpdateAIJob(filter: $filter, owner: $owner) {
      id
      userId
      feature
      args
      status
      progress
      currentStep
      totalSteps
      outputLoc
      payload
      info
      createdAt
      user {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      runpodJobs {
        items {
          id
          aiJobId
          userId
          feature
          args
          status
          progress
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAIJob = /* GraphQL */ `
  subscription OnDeleteAIJob(
    $filter: ModelSubscriptionAIJobFilterInput
    $owner: String
  ) {
    onDeleteAIJob(filter: $filter, owner: $owner) {
      id
      userId
      feature
      args
      status
      progress
      currentStep
      totalSteps
      outputLoc
      payload
      info
      createdAt
      user {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      runpodJobs {
        items {
          id
          aiJobId
          userId
          feature
          args
          status
          progress
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateRunpodJob = /* GraphQL */ `
  subscription OnCreateRunpodJob(
    $filter: ModelSubscriptionRunpodJobFilterInput
    $owner: String
  ) {
    onCreateRunpodJob(filter: $filter, owner: $owner) {
      id
      aiJobId
      userId
      feature
      args
      status
      progress
      payload
      info
      aiJob {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateRunpodJob = /* GraphQL */ `
  subscription OnUpdateRunpodJob(
    $filter: ModelSubscriptionRunpodJobFilterInput
    $owner: String
  ) {
    onUpdateRunpodJob(filter: $filter, owner: $owner) {
      id
      aiJobId
      userId
      feature
      args
      status
      progress
      payload
      info
      aiJob {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteRunpodJob = /* GraphQL */ `
  subscription OnDeleteRunpodJob(
    $filter: ModelSubscriptionRunpodJobFilterInput
    $owner: String
  ) {
    onDeleteRunpodJob(filter: $filter, owner: $owner) {
      id
      aiJobId
      userId
      feature
      args
      status
      progress
      payload
      info
      aiJob {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
