import { scale, verticalScale } from 'react-native-size-matters';

import FacepicUploadScreen from './faceswap/FacepicUploadScreen';
import FaceswapResultScreen from './faceswap/FaceswapResultScreen';
import JobScreen from './JobScreen';
import MainFaceswapScreen from './faceswap/MainFaceswapScreen';
import PaymentScreen from './PaymentScreen';
import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import VideoUploadScreen from './faceswap/VideoUploadScreen';
import avatarState from '../atoms/avatarState';
import { createStackNavigator } from '@react-navigation/stack';
import { isDesktopWeb } from './PlatformTools';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';

const Stack = createStackNavigator();

export default function FaceswapNavigator({ navigation }) {
    const [user, setUser] = useRecoilState(userState);

    return (
        <Stack.Navigator
            // initialRouteName='VideoUpload'
            screenOptions={{
                headerStyle: {
                    backgroundColor: '#F2F2F2',
                    borderBottomWidth: 0,
                },
                headerTitleAlign: 'center',
                headerTitleStyle: { fontSize: scale(5) },
            }}>
            <Stack.Screen name="FaceswapIntro" component={MainFaceswapScreen} options={{
                headerShown: false,
                title: "Unycorn - Faceswapper"
            }} />
            <Stack.Screen name="FacepicUpload" component={FacepicUploadScreen}
                options={{
                    title: 'Step 1 of 2',
                    headerTitleStyle: { fontSize: isDesktopWeb ? scale(5) : scale(16), fontWeight: 'normal' },
                }} />
            <Stack.Screen name="VideoUpload" component={VideoUploadScreen} options={{
                title: 'Step 2 of 2',
                headerTitleStyle: { fontSize: isDesktopWeb ? scale(5) : scale(16), fontWeight: 'normal' },
            }} />
            <Stack.Screen name="PaymentScreen" component={PaymentScreen} options={{
                title: 'Payment',
                headerShown: false,
            }} />
            <Stack.Screen name="JobScreen" component={JobScreen} options={{
                title: 'Art Generation',
                headerShown: false,
            }} />
            <Stack.Screen name="FaceswapResultScreen" component={FaceswapResultScreen} options={{
                title: 'Result',
                headerShown: false,
            }} />
        </Stack.Navigator>
    );
}

const styles = ScaledSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50@mvs',
    },
    credits: {
        marginTop: '20@mvs',
        fontSize: isDesktopWeb ? '10@ms' : '25@ms',
        color: 'grey'
    },
    button: {
        color: 'white',
        fontSize: isDesktopWeb ? '10@mvs' : '20@ms',
        lineHeight: isDesktopWeb ? 'auto' : '20@mvs',
    },
    buttonBox: {
        margin: '10@mvs',
        width: isDesktopWeb ? '100@ms' : '120@ms',
        height: isDesktopWeb ? '20@mvs' : '40@ms',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#282C34',
        borderRadius: '5@mvs',
    },
    buttonLabel: {
        color: 'white',
        fontSize: isDesktopWeb ? '9@ms' : '15@ms',
        lineHeight: isDesktopWeb ? '20@mvs' : '25@mvs'
    },
});