import { ActivityIndicator, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { scale, verticalScale } from 'react-native-size-matters';

import { Image } from 'expo-image';
import { Storage } from 'aws-amplify';

const urlCache = new Map();
function S3Image({ source, style, key, transition, onLoad }) {
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(true);


    const fetchPresignedUrl = async () => {
        setLoading(true);
        try {
            if (urlCache.has(source)) {
                setImageUrl(urlCache.get(source));
            } else {
                const url = await Storage.get(source, { level: "private", expires: 3600 * 24 * 7 });
                urlCache.set(source, url); // Cache the URL
                setImageUrl(url);
            }
        } catch (error) {
            console.error("Failed to fetch presigned URL:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleImageError = () => {
        // If the image fails to load, try fetching a new presigned URL
        urlCache.delete(source);
        fetchPresignedUrl();
    };

    useEffect(() => {
        fetchPresignedUrl();
    }, [source]);


    return (
        <>
            {loading ? (
                <ActivityIndicator size={scale(10)} style={{ marginTop: verticalScale(10) }} />
            ) : (
                <Image source={{ uri: imageUrl }} style={style} key={key} transition={transition} onLoad={onLoad} onError={handleImageError} />
            )
            }
        </>
    );
};

export default React.memo(S3Image);
