import asyncStorage from '../utils/storage';
import { atom } from 'recoil';

const asyncStorageEffect = ({ setSelf, onSet }) => {
    asyncStorage.retrieveData("user").then((value) => {
        if (value != null) {
            setSelf(JSON.parse(value));
        }
    });

    onSet((newValue, _, isReset) => {
        if (isReset) {
            asyncStorage.remove("user")
        } else {
            asyncStorage.storeData("user", JSON.stringify(newValue));
        }
    });
};


const userState = atom({
    key: 'user',
    default: {},
    // effects: [
    // asyncStorageEffect,
    // ]
});

export default userState;