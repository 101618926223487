import { ActivityIndicator, Alert, Modal, Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import { Entypo } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Image } from 'expo-image';
import { Pressable } from 'react-native';
import React from 'react';
import S3Image from './S3Image';
import SaveContentButton from './SaveContentButton';
import { ScaledSheet } from 'react-native-size-matters';
import { isDesktopWeb } from './PlatformTools';

const ImageModal = ({ imageUrl, onClose }) => {
    const [loading, setLoading] = React.useState(true);

    return (
        <Modal
            animationType="fade"
            transparent={false}
            visible={!!imageUrl}
            onRequestClose={onClose}
        >
            <View style={styles.container}>
                {loading && (
                    <View style={{ height: '100%', justifyContent: 'center' }}>
                        <ActivityIndicator size={scale(20)} style={{ alignSelf: 'center' }} color="red" />
                    </View>
                )
                }
                {imageUrl && <S3Image source={imageUrl} style={styles.image} onLoad={() => setLoading(false)} />}
                <View style={styles.bottomControls}>
                    <SaveContentButton source={imageUrl} styleButton={styles.button}>
                        <Entypo name="download" size={isDesktopWeb ? verticalScale(15) : verticalScale(30)} color="#282C34" />
                    </SaveContentButton>

                    <Pressable onPress={onClose}>
                        <View style={styles.button}>
                            <FontAwesome name="close" size={isDesktopWeb ? verticalScale(15) : verticalScale(30)} color="#282C34" />
                        </View>
                    </Pressable>
                </View>
            </View>
        </Modal>
    );
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F2F2F2',
        padding: '15@mvs'
    },
    image: {
        width: isDesktopWeb ? '512@ms' : '350@ms',
        height: isDesktopWeb ? '768@mvs' : '512@mvs',
        contentFit: 'contain'
    },
    bottomControls: {
        margin: isDesktopWeb ? '10@ms' : '0@ms',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    bottomControlsSize: {
        size: isDesktopWeb ? '15@s' : '20@s',
    },
    button: {
        // backgroundColor: '#282C34',
        borderRadius: '10@mvs',
        padding: '5@mvs',
        marginHorizontal: '5@mvs'
    }
});
export default ImageModal;