import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as Progress from 'react-native-progress';

import { Linking, Modal, Platform, Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import { Button } from 'react-native-paper';
import { Pressable } from 'react-native';
import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import { Storage } from 'aws-amplify';
import { useState } from 'react';

export default function SaveContentButton({ source, styleButton, children, styleText }) {
    const [progress, setProgress] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);

    const downloadFile = async (url, filename) => {
        setModalVisible(true);
        const downloadResumable = FileSystem.createDownloadResumable(
            url,
            FileSystem.cacheDirectory + filename,
            {},
            downloadProgress => {
                let progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
                progress = parseFloat(progress.toFixed(2));
                setProgress(progress);
                console.log("Progress: " + progress);
            }
        );

        try {
            const result = await downloadResumable.downloadAsync();
            setModalVisible(false);
            console.log('Finished downloading', result.uri);
            return result.uri;
        } catch (error) {
            setModalVisible(false);
            console.error(error);
        }
    };



    const handleSave = async () => {
        const contentURL = await Storage.get(source, { level: "private", expires: 3600 });

        if (Platform.OS === 'web') {
            return Linking.openURL(contentURL);
        }
        const { status } = await MediaLibrary.requestPermissionsAsync();
        if (status !== 'granted') {
            alert('We need permissions to save photos and videos!');
            return;
        }

        try {
            const filename = source.split('/').pop().split('?')[0];
            const localUri = await downloadFile(contentURL, filename);

            const asset = await MediaLibrary.createAssetAsync(localUri);
            await MediaLibrary.createAlbumAsync('Download', asset, false);
            alert('Item saved to gallery!');
        } catch (error) {
            console.error(error);
            alert('There was an error saving the item. Please try again later.');
        }
    };


    return (
        <View>
            <Pressable onPress={handleSave}>
                <View style={[styleButton || styles.button]}>
                    {children}
                </View>
            </Pressable>
            <View style={styles.centeredView}>
                <Modal
                    animationType="slide"
                    transparent={false}
                    visible={modalVisible}
                >
                    <View style={styles.centeredView}>
                        <View style={[styles.modal, styles.downloadProgress]}>
                            <Text style={styles.modalTextStyle}>Downloading...</Text>
                            <Progress.Bar progress={progress} style={styles.downloadProgressBar} />
                        </View>
                    </View>
                </Modal>
            </View>
        </View>
    );
}

const styles = ScaledSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modal: {
        height: '200@vs',
        justifyContent: 'center',
        alignItems: 'center',
    },
    downloadProgress: {
        padding: '20@ms',
        width: '250@s',
        alignSelf: 'center',
        alignItems: 'center',
    },
    downloadProgressBar: {
        // width: '220@s',
        // height: '20@vs',
        alignSelf: 'center',
    },
    modalTextStyle: {
        fontSize: '15@ms',
    },
    button: {

    }
})