import { API, graphqlOperation } from 'aws-amplify';
import { Linking, Pressable, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { scale, verticalScale } from 'react-native-size-matters';

import { Auth } from 'aws-amplify';
import Constants from 'expo-constants';
import { ScaledSheet } from 'react-native-size-matters';
import StripeBuyButton from './StripeBuyButton';
import { isDesktopWeb } from './PlatformTools';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';

function AccountScreen({ navigation }) {
    const [user, setUser] = useRecoilState(userState);

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            setUser(null);
        } catch (error) {
            console.log('error signing out user', error);
        }
    };

    const handleBuyButtonClick = () => {
        const client_reference_id = user.username; // + "__" + user.attributes.sub;
        const paymentLink = `https://buy.stripe.com/${Constants.expoConfig.extra.stripePaymentLinkID}?client_reference_id=${client_reference_id}&email=${user.s.email}`;
        Linking.openURL(paymentLink);
    }

    return (
        <View style={styles.container}>
            <View style={styles.userInfo}>
                <Text style={styles.loggedInAs}>Logged in as <Text style={{ color: 'black' }}>{user.email} </Text><Text style={styles.loggedInAs} onPress={handleSignOut}>(Sign out)</Text> </Text>
            </View>

            <View style={styles.mainTextView}>
                <Text style={[styles.mainText, { fontWeight: 'bold' }]}>What are Credits for? 🌟</Text>
                <Text style={[styles.mainText, { marginVertical: isDesktopWeb ? verticalScale(5) : verticalScale(15) }]}>
                    We don't do subscription fees. You only pay for what you use. No hidden costs, no sneaky renewals.
                    Think of credits like arcade tokens, but for AI to generate content for you! 1 credit = 1 cent (USD).
                </Text>

                <Text style={styles.mainText}>
                    👩‍🦱 200 credits - Get an AI-made collection of photos of yourself in various styles.
                </Text>
                <Text style={[styles.mainText, { marginTop: isDesktopWeb ? verticalScale(2) : verticalScale(3) }]}>
                    🎭 100 credits - Replace a face in any video or GIF. Create personalized memes.
                </Text>
                <Text style={[styles.mainText, { marginTop: isDesktopWeb ? verticalScale(5) : verticalScale(15) }]}>
                    Stay tuned for more magical features.
                </Text>
            </View>
            <View style={styles.creditsBox}>
                <Text style={styles.credits}>You have <Text style={{ fontWeight: 'bold' }}>{user.balance}</Text> credits</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
                <Text style={styles.priceInfo}>($5 = 500 credits)</Text>
            </View>
            <View style={styles.buttonView}>
                <StripeBuyButton label="Buy credits" containerStyle={styles.buyButton} labelStyle={styles.buyButtonLabel} />
            </View>
            <View style={styles.termsView}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.termsText}>
                        Check out our{" "}
                    </Text>
                    <Pressable onPress={() => Linking.openURL('https://www.termsfeed.com/live/bbbef761-3b2e-4ab3-9cdb-8b2180bac8d8')}>
                        <Text style={[styles.termsText, styles.linkText]}>Privacy Policy</Text>
                    </Pressable>
                    <Text style={styles.termsText}>{" "}and{" "}</Text>
                    <Pressable onPress={() => Linking.openURL('https://www.termsfeed.com/live/805f0759-0bb9-4c90-a32a-51aa5f1946ac')}>
                        <Text style={[styles.termsText, styles.linkText]}>Terms of Use</Text>
                    </Pressable>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.termsText}>
                        Need help? Drop us an email at{" "}
                    </Text>
                    <Pressable onPress={() => Linking.openURL('mailto:support@unycorn.app')}>
                        <Text style={[styles.termsText, styles.linkText]}>support@unycorn.app</Text>
                    </Pressable>
                </View>
            </View>
        </View >
    )
}
const styles = ScaledSheet.create({
    container: {
        justifyContent: 'center',
        // alignItems: 'center',
        margin: '10@mvs',
        marginTop: '10@mvs',
    },
    userInfo: {
        paddingHorizontal: '15@ms'
    },
    creditsBox: {
        marginTop: isDesktopWeb ? '25@mvs' : '30@mvs',
        width: isDesktopWeb ? '130@ms' : '195@ms',
        borderRadius: '5@mvs',
        backgroundColor: '#FFCC5C',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        padding: '10@mvs',
    },
    credits: {
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
    },

    loggedInAs: {
        color: '#828385',
        fontSize: isDesktopWeb ? '8@ms' : '12@ms',
    },
    buttonView: {
        alignItems: 'center',
        marginTop: isDesktopWeb ? '25@mvs' : '30@mvs',
    },
    buyButton: {
        width: isDesktopWeb ? '100@ms' : '150@ms',
        height: isDesktopWeb ? '20@mvs' : '45@mvs',
    },
    buyButtonLabel: {
        color: 'white',
        fontSize: isDesktopWeb ? '9@ms' : '20@ms',
    },
    priceInfo: {
        marginTop: '5@mvs',
        fontSize: isDesktopWeb ? '8@ms' : '12@ms',
    },
    mainTextView: {
        marginTop: isDesktopWeb ? '25@mvs' : '12@mvs',
        paddingHorizontal: '15@ms',
    },
    mainText: {
        fontSize: isDesktopWeb ? '8@ms' : '13@ms',
    },
    termsView: {
        marginTop: '40@mvs',
        paddingHorizontal: '15@ms',
    },
    termsText: {
        marginTop: '5@mvs',
        fontSize: isDesktopWeb ? '8@ms' : '12@ms',
    },
    linkText: {
        color: '#ED2647',
        textDecorationLine: 'underline',
    }

});



export default AccountScreen;