/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      identityId
      balance
      totalTransactions
      totalGenerations
      totalFailed
      totalPaid
      jobs {
        items {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIJob = /* GraphQL */ `
  query GetAIJob($id: ID!) {
    getAIJob(id: $id) {
      id
      userId
      feature
      args
      status
      progress
      currentStep
      totalSteps
      outputLoc
      payload
      info
      createdAt
      user {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      runpodJobs {
        items {
          id
          aiJobId
          userId
          feature
          args
          status
          progress
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAIJobs = /* GraphQL */ `
  query ListAIJobs(
    $id: ID
    $filter: ModelAIJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAIJobs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listJobsByUser = /* GraphQL */ `
  query ListJobsByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listJobsByUserByFeature = /* GraphQL */ `
  query ListJobsByUserByFeature(
    $userId: ID!
    $createdAtFeature: ModelAIJobByUserByTimeByFeatureCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobsByUserByFeature(
      userId: $userId
      createdAtFeature: $createdAtFeature
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRunpodJob = /* GraphQL */ `
  query GetRunpodJob($id: ID!) {
    getRunpodJob(id: $id) {
      id
      aiJobId
      userId
      feature
      args
      status
      progress
      payload
      info
      aiJob {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRunpodJobs = /* GraphQL */ `
  query ListRunpodJobs(
    $id: ID
    $filter: ModelRunpodJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRunpodJobs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        aiJobId
        userId
        feature
        args
        status
        progress
        payload
        info
        aiJob {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRunpodJobsByAiJob = /* GraphQL */ `
  query ListRunpodJobsByAiJob(
    $aiJobId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRunpodJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRunpodJobsByAiJob(
      aiJobId: $aiJobId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        aiJobId
        userId
        feature
        args
        status
        progress
        payload
        info
        aiJob {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
