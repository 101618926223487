import { Platform } from 'react-native';
import axios from 'axios';

export const getPresignedUrlAndUpload = async (files, fileType, sessionId, setUploading, onFileUploadCallback) => {

    try {
        const fileNames = files.map(file => file.name);
        const response = await axios.post(
            'https://exwqh7eithxs7le3trpl5d3pde0apafb.lambda-url.us-west-2.on.aws/',
            {
                fileType: fileType,
                fileNames: fileNames,
                sessionId: sessionId
            }
        );

        let onUploadProgress = (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // setUploading(percentCompleted);
        }

        // For multifile uploads, don't track progress for every file
        if (files.length > 1)
            onUploadProgress = null;
        const urlsAndFields = response.data;
        for (let idx in urlsAndFields) {
            const { url, fields } = urlsAndFields[idx];
            await uploadToS3(files[idx], url, fields, onUploadProgress);
            if (onFileUploadCallback) {
                onFileUploadCallback(files[idx]);
            }

            // For multifile uploads, update progress per file
            if (files.length > 1) {
                const percentCompleted = Math.round((parseInt(idx) + 1) / files.length * 100);
                setUploading(percentCompleted);
            }
        }
    } catch (error) {
        console.log(error);
    }
};

const uploadToS3 = async (file, url, fields, onUploadProgress) => {
    const formData = new FormData();
    Object.keys(fields).forEach(key => formData.append(key, fields[key]));

    if (Platform.OS === 'web') {
        formData.append('file', file);
    } else {
        // Upload as blob
        formData.append('file', {
            name: file.name,
            type: file.type,
            uri: file.uri,
        });
    }

    const axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: onUploadProgress
    };

    return axios.post(url, formData, axiosConfig)
        .then((response) => {
            console.log('Response from server: ', response);
        })
        .catch((error) => {
            console.log('Error uploading file: ', error);
        });
    ;
}


