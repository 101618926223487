import * as Linking from 'expo-linking';
import * as subscriptions from '../src/graphql/subscriptions';

import { API, Hub, graphqlOperation } from 'aws-amplify';
import { ActivityIndicator, FlatList, Platform, Pressable, Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import CustomButton from './CustomButton';
import { Entypo } from '@expo/vector-icons';
import { Image } from 'expo-image';
import ImageModal from './ImageModal';
import React from 'react';
import S3Image from './S3Image';
import SaveFilesButton from './SaveFilesButton';
import { ScaledSheet } from 'react-native-size-matters';
import { Storage } from 'aws-amplify';
import StripeBuyButton from './StripeBuyButton';
import asyncStorage from '../utils/storage';
import avatarState from '../atoms/avatarState';
import { isDesktopWeb } from './PlatformTools';
import { timeAgo } from '../utils/formatters';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';

export default function AvatarResultScreen({ job, navigation, goBack }) {
    const { user, setUser } = useRecoilState(userState);
    const [avatarProps, setAvatarProps] = useRecoilState(avatarState);
    const [imageURLs, setImageURLs] = React.useState([]);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [firstImageLoaded, setFirstImageLoaded] = React.useState(false);

    useEffect(() => {
        const imageFilenames = job?.payload?.image_filenames || avatarProps?.result?.image_filenames;
        const jobId = job?.id || avatarProps?.job?.id;

        if (imageFilenames.length > 0 && jobId) {
            setLoading(false);
        }
    }, []);

    const resetAndGoBack = () => {
        setAvatarProps(avatarProps, {});
        navigation.navigate("AvatarIntro");
    }

    const renderItem = ({ item, index }) => (
        <View style={styles.resultImageView}>
            <Pressable onPress={() => setSelectedImage("avatars/" + job.id + "/" + item)}>
                <S3Image source={"avatars/" + job.id + "/thumbnails400/" + item} style={styles.resultImage} key={index} transition={100} onLoad={() => {
                    if (!firstImageLoaded) setFirstImageLoaded(true);
                }}
                />
            </Pressable>
        </View>
    );

    if (loading) {
        return (
            <View style={styles.container}>
                <View style={styles.content}>
                    <ActivityIndicator size={scale(30)} color="grey" />
                </View>
            </View>
        )
    }


    return (
        <View style={styles.container}>
            <View style={styles.content}>

                <View style={styles.headerControls}>
                    {goBack && (
                        <CustomButton
                            label="Back to projects"
                            onPress={goBack}
                            containerStyle={styles.topNavigationButton}
                            labelStyle={{ fontSize: isDesktopWeb ? scale(6) : scale(16) }}
                        />
                    )}
                    {job.payload.image_filenames.length > 0 && (
                        <SaveFilesButton
                            sourceList={job.payload.image_filenames.map((filename) => "avatars/" + job.id + "/" + filename)}
                            styleButton={styles.downloadButton}
                            styleText={styles.button}
                        />
                    )}
                </View>
                {job?.createdAt && (
                    <Text style={[styles.headerText]}>Created {timeAgo(job?.createdAt)}</Text>
                )}

                {!firstImageLoaded && imageURLs?.length > 0 && (
                    <View style={{ marginTop: verticalScale(30), alignItems: 'center' }}>
                        <Text style={[styles.headerText, { marginBottom: verticalScale(10), color: 'black' }]}>Please wait while your photos are being loaded...</Text>
                        <ActivityIndicator size={scale(20)} color="red" />
                    </View>
                )}
                <FlatList
                    data={job.payload.image_filenames}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => index.toString()}
                    contentContainerStyle={styles.resultView}
                    showsVerticalScrollIndicator={false}
                    onEndReachedThreshold={0.2}
                />

                {selectedImage && (
                    <ImageModal
                        imageUrl={selectedImage}
                        onClose={() => setSelectedImage(null)}
                    />
                )}


                <View style={styles.bottomControls}>
                    <CustomButton
                        label="Create new avatars"
                        containerStyle={{ marginTop: isDesktopWeb ? verticalScale(10) : verticalScale(30) }}
                        labelStyle={{ fontSize: isDesktopWeb ? scale(6) : scale(16) }}
                        onPress={resetAndGoBack} />
                </View>
            </View>
        </View>
    );
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        overflow: 'auto',
        scrollbarWidth: 'none',  // for Firefox
        '&::-webkit-scrollbar': {
            display: 'none'  // for Chrome, Safari, Edge
        },
    },
    headerControls: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '25@mvs'
    },
    headerImage: {
        // marginTop: '1@mvs',
        width: '100%',
        height: isDesktopWeb ? '210@mvs' : '220@mvs',
        resizeMode: 'cover',
    },
    content: {
        flex: 1,
        margin: '25@ms',
    },
    headerText: {
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
        color: 'grey'
    },
    headerTextLine2: {
        marginTop: isDesktopWeb ? '10@mvs' : '10@mvs',
    },
    balanceView: {
        marginVertical: '15@mvs',
        backgroundColor: '#FFCC5C',
        padding: '10@ms',
        borderRadius: '5@ms',
    },
    balanceText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
    },
    solutionText: {
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
    },
    resultView: {
        // padding: 5,
        flexDirection: 'row',
        flexWrap: 'wrap',  // Allow the images to wrap to the next line
        justifyContent: 'space-between',
    },
    resultImageView: {
        flex: 1,
        marginVertical: '5@mvs',
    },
    resultImage: {
        width: isDesktopWeb ? '100@ms' : '140@ms',
        height: isDesktopWeb ? '100@mvs' : '140@mvs',
        aspectRatio: 1,
        // resizeMode: 'contain',
    },
    bottomControls: {
        alignItems: 'center',
    },
    topNavigationButton: {
        // marginTop: isDesktopWeb ? '10@mvs' : '5@mvs',
        width: isDesktopWeb ? '100@ms' : '160@ms',
        height: isDesktopWeb ? '20@mvs' : '40@mvs',
    }
})