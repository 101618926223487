import { API, Analytics, graphqlOperation } from 'aws-amplify';

import Constants from 'expo-constants';
import { createAIJob } from '../src/graphql/mutations';

export async function startAvatarTrainingJob(user, avatarProps, setAvatarProps) {
    const currentDate = new Date().toISOString();

    const aiJobHandlerURL = Constants.expoConfig.extra.aiJobHandlerURL;
    if (!user || !user.username || !user.identityId || !avatarProps.gender || !avatarProps.job.id) {
        alert("Settings are loading. Please wait a minute and try again...");
        console.log("AvatarState is not ready", user, avatarProps);
        return { error: "AvatarState is not ready" };
    };

    const aiJob = await API.graphql(graphqlOperation(createAIJob,
        {
            input: {
                id: avatarProps.job.id,
                userId: user.username,
                feature: "avatars",
                status: "CREATED",
                outputLoc: "private/" + user.identityId + "/avatars/" + avatarProps.job.id,
                createdAt: currentDate,
            }
        }));

    Analytics.record({
        name: 'job_created',
        attributes: { feature: 'avatars' }
    });



    return fetch(`${aiJobHandlerURL}?is_generation_only1=true&user_id=${user.username}&identity_id=${encodeURIComponent(user.identityId)}&gender=${avatarProps.gender}&job_id=${encodeURIComponent(avatarProps.job.id)}&feature=avatars`)
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                console.log("!!! Error in response", data.error);
                setAvatarProps({ ...avatarProps, error: error });
                return { error: data.error };
            }
            console.log('Job is created', data);
            const job = { ...avatarProps.job, status: "SUBMITTED", progress: 0, feature: "avatars" };
            setAvatarProps({ ...avatarProps, job: job });
            return job;
        })
        .catch(error => {
            console.log("!!! Error in fetch", error);
            return { error: error };
        });
};