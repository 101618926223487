import asyncStorage from '../utils/storage';
import { atom } from 'recoil';

const asyncStorageEffect = ({ setSelf, onSet }) => {
    asyncStorage.retrieveData("avatar:state").then((value) => {
        console.log("Retrieved avatar state", value);
        if (value != null) {
            setSelf({ ...JSON.parse(value), loading: false });
        } else {
            setSelf(prevState => ({ ...prevState, loading: false }));
        }
    });

    onSet((newValue, _, isReset) => {
        if (isReset) {
            asyncStorage.remove("avatar:state")
        } else {
            console.log("Storing avatar state", newValue);
            asyncStorage.storeData("avatar:state", JSON.stringify({ ...newValue, loading: false }));
        }
    });
};

const avatarState = atom({
    key: 'avatarProps',
    default: {
        gender: "female",
        files: [],
        job: { id: null },
        result: { image_filenames: [] },
        loading: false
    },
    // effects: [
    // asyncStorageEffect,
    // ]

});

export default avatarState;