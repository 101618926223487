// For debugging
import 'expo-dev-client';

import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

import { API, Amplify, Analytics, Auth, Hub, Logger, graphqlOperation } from 'aws-amplify';
import { ActivityIndicator, Platform, View } from 'react-native';
import React, { useEffect, useState } from 'react';

import Constants from 'expo-constants';
import CustomAuthenticator from './components/CustomAuthenticator.web';
import { LoggerProvider } from './components/LoggerContext';
import MainMemberScreen from './components/MainMemberScreen';
import PublicFrontpage from './components/PublicFrontpage';
import { RecoilRoot } from 'recoil';
import { SafeAreaView } from 'react-native';
import { ScaledSheet } from 'react-native-size-matters';
import { StatusBar } from 'react-native';
import { Storage } from "aws-amplify";
import awsconfig from './aws-exports';
import { getUser } from "./src/graphql/queries";
import { isDesktopWeb } from './components/PlatformTools';
import { scale } from 'react-native-size-matters';

const DEV = __DEV__ || Constants.expoConfig.extra.env === "development";
const logLevel = __DEV__ ? 'DEBUG' : 'INFO';
const logger = new Logger('app', logLevel);


// to enable Analytics
Analytics.enable();

Analytics.autoTrack('session', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the attributes of the event, you can either pass an object or a function 
  // which allows you to define dynamic attributes
  attributes: {
    attr: 'sessionStart'
  },
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: 'AWSPinpoint'
});

const oauth = {
  ...awsconfig.oauth,
  domain: DEV ? "auth.dev.unycorn.app" : "auth.unycorn.app",
  redirectSignIn: Platform.OS === "web" ? Linking.createURL("/") : "unycorn://login/",
  redirectSignOut: Platform.OS === "web" ? Linking.createURL("/") : "unycorn://logout/",
}

if (Platform.OS === "ios") {
  oauth.urlOpener = async (url, redirectUrl) => {
    logger.debug("Opening URL:", url);
    try {
      const result = await WebBrowser.openAuthSessionAsync(url, redirectUrl, {
        preferEphemeralSession: true, // private session, so doesn't show dialog BUT asks apple verification code every time
      });
      logger.debug("Returned URL:" + result.url);

      if (result.type === 'success' && result.url && result.url.includes("login")) {
        try {
          await WebBrowser.dismissBrowser();

          logger.debug("Opening redirect URL")
          // Doesn't open app in dev mode without exp+. And you can't create a
          // proper redirect link like "exp+unycorn://", so I had to add this hack.
          return Linking.openURL("exp+" + result.url);
        } catch (error) {
          logger.error("Error opening redirect URL", result.url);
        }
      }
    } catch (error) {
      logger.error('WebBrowser error:', error);
    }
  }
}

const updatedAwsConfig = {
  ...awsconfig,
  oauth: oauth,
}

logger.debug("__DEV__:" + __DEV__);
logger.debug("Expo ENVIRONMENT: " + process.env.EXPO_PUBLIC_ENVIRONMENT);
logger.debug("AWS REGION:" + awsconfig.aws_user_files_s3_bucket_region);
logger.debug("Redirect URI", Linking.createURL());
Amplify.configure(updatedAwsConfig);

export default function App() {
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState();


  useEffect(() => {
    setAuthState("loading");
    getCurrentUser();
    // Subscribe to auth state changes
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log(`Received auth event: ${event}`, data);
      switch (event) {
        case 'signIn':
          logger.debug("Auth event", data);
          if (!user) {
            setAuthState("loading");
            getCurrentUser();
          }
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          logger.debug("Sign in failure", data);
          break;
        default:
          break;
      }
    });

    // Clean up subscription
    return () => unsubscribe();

  }, []);

  const getCurrentUser = async () => {
    Auth.currentAuthenticatedUser()
      .then(_user => {
        logger.debug("Got current user", _user);
        _user = {
          username: _user.username,
          email: _user.attributes.email,
        }


        fetchUserAccount(_user).then((_user_with_balance) => {
          logger.debug("Got balance", _user_with_balance);
          getIdentityId(_user_with_balance);
        })

      })
      .catch((exp) => {
        setUser(null);
        setAuthState(false);
        logger.info("Can't get current user:", exp);
      });
  }

  const getIdentityId = async (_user) => {
    Auth.currentCredentials().then(creds => {
      logger.debug("Got identity id", creds, _user);
      setUser({ ..._user, identityId: creds.identityId });
      setAuthState(false);
    }).catch((exp) => {
      setAuthState(false);
      logger.warn("Can't get current credentials:", exp);
    });
  }


  async function fetchUserAccount(_user) {
    try {
      const userData = await API.graphql(graphqlOperation(getUser, { id: _user.username }));
      const data = userData.data.getUser;

      if (data) {
        // console.log("!!! Got balance", data);
        return {
          ..._user,
          balance: data.balance,
          email: data.email,
        }
      }
    } catch (err) {
      logger.error('Error fetching user account:', err);
    }
  }

  if (authState === "loading") {
    return (
      <View style={[styles.container, { justifyContent: 'center', alignItems: 'center' }]}>
        <View >
          <ActivityIndicator size={scale(30)} color="grey" />
        </View>
      </View>
    )
  }

  return (
    <RecoilRoot>
      <LoggerProvider>
        <SafeAreaView style={{ flex: 1 }}>
          <StatusBar barStyle="light-content" backgroundColor="#000000" />
          <View style={styles.container}>
            <View style={styles.body} >
              {
                user
                  ?
                  <MainMemberScreen userAuth={user} />
                  :
                  <>
                    {
                      authState === "signIn"
                        ?
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <View style={styles.authBox}>
                            <CustomAuthenticator />
                          </View>
                        </View>
                        : <PublicFrontpage setAuthState={setAuthState} />
                    }

                  </>
              }
            </View>
          </View >
        </SafeAreaView>
      </LoggerProvider>
    </RecoilRoot>
  );
}

const styles = ScaledSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F2F2F2',
    // alignItems: 'center',
  },
  header: {
    alignSelf: 'stretch',
    alignItems: 'center',
    backgroundColor: '#282C34',
  },
  logo: {
    alignItems: 'center',
  },
  body: {
    // alignItems: 'center',
    flex: 1,
    backgroundColor: '#282C34',
    // width: '100%',
  },
  footer: {
    alignItems: 'center',
    color: '#ED2647',
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'repeat',
  },
  authBox: {
    width: isDesktopWeb ? '180@ms' : '360@ms',
    height: 'auto',
    margin: '10@ms',
    backgroundColor: '#282C34',
    alignSelf: 'center',
  }

});
