import { API, Analytics, graphqlOperation } from 'aws-amplify';
import { ActivityIndicator, Linking, Modal, Pressable, Text, View } from 'react-native';
import { isDesktopWeb, valueFor } from '../PlatformTools';
import { scale, verticalScale } from 'react-native-size-matters';

import Constants from 'expo-constants';
import CustomButton from '../CustomButton';
import CustomVideo from '../CustomVideo';
import FileUploader from '../FileUploader';
import { FontAwesome5 } from '@expo/vector-icons';
import { Image } from 'expo-image';
import MasonryList from '@react-native-seoul/masonry-list';
import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import { createAIJob } from '../../src/graphql/mutations';
import faceswapJobState from '../../atoms/faceswapJobState';
import { faceswapVideoList } from '../../utils/media';
import { useLogger } from '../LoggerContext';
import { useRecoilState } from 'recoil';
import userState from '../../atoms/userState';
import { v4 as uuidv4 } from 'uuid';

export default function VideoUploadScreen({ navigation }) {
    const PRICE_PER_GENERATION = 100;
    const [user, setUser] = useRecoilState(userState);
    const [faceswapJob, setFaceswapJob] = useRecoilState(faceswapJobState);
    const [videoFile, setVideoFile] = React.useState(null);
    const [aspectRatio, setAspectRatio] = React.useState(1); // Initialize to 1 to avoid division by zero
    const [videoURI, setVideoURI] = React.useState(null);
    const [videoPoster, setVideoPoster] = React.useState(null);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [useVideoCollection, setUseVideoCollection] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [isJobStarted, setIsJobStarted] = React.useState(false);
    const currentDate = new Date().toISOString();
    const aiJobHandlerURL = Constants.expoConfig.extra.aiJobHandlerURL;
    const logger = useLogger();


    const handleImageLoad = (metaData) => {
        const { width, height } = metaData.source;
        if (height !== 0) {
            setAspectRatio(width / height);
        } else {
            setAspectRatio(1);
        }
    };

    const handleLoad = (metaData) => {
        const { width, height } = metaData.naturalSize;
        if (height !== 0) {
            setAspectRatio(width / height);
        }
    };

    function showVideoGallery() {
        setModalVisible(true);
    }

    function selectVideo(video) {
        video.name = video.name.replace(".gif", ".mp4");
        setVideoFile(video);
        setVideoURI(video.uri);
        setVideoPoster(video?.poster);
        setUseVideoCollection(true);
        setModalVisible(false);
    }

    function setUploadedVideo(videoFile) {

        if (videoFile.hasOwnProperty("uri")) {
            setVideoURI(videoFile.uri);
        } else {
            setVideoURI(URL.createObjectURL(videoFile));
        }

        setVideoFile(videoFile);
        setUseVideoCollection(false);
    }

    function resetVideo() {
        setVideoFile(null);
        setVideoURI(null);
        setUseVideoCollection(false);
    }

    async function tryToStartFaceswapJob() {
        const jobId = uuidv4();

        if (user.balance < PRICE_PER_GENERATION) {
            navigation.navigate('PaymentScreen', { jobId: jobId, price: PRICE_PER_GENERATION, callbackFn: () => startFaceswapJob(jobId) });
        } else {
            startFaceswapJob(jobId);
        }
    }

    const openGiphy = () => {
        Linking.openURL('https://www.giphy.com');
    };

    async function startFaceswapJob(jobId) {
        setIsJobStarted(true);
        const videoFileName = videoFile.name.split('/').pop();

        const aiJob = await API.graphql(graphqlOperation(createAIJob,
            {
                input: {
                    id: jobId,
                    userId: user.username,
                    feature: "faceswap",
                    args: JSON.stringify({ videofile: videoFileName, imagefile: faceswapJob.imageFilename, use_video_collection: useVideoCollection }),
                    status: "CREATED",
                    outputLoc: "private/" + user.identityId + "/faceswap/" + jobId,
                    createdAt: currentDate,
                }
            }));

        setFaceswapJob({ id: jobId, imageFile: faceswapJob.imageFilename, videoFile: videoFile.name, status: "CREATED", feature: "faceswap", progress: 0 });

        Analytics.record({
            name: 'job_created',
            // Attribute values must be strings
            attributes: { feature: 'faceswap' }
        });

        fetch(`${aiJobHandlerURL}?job_id=${jobId}&user_id=${user.username}&identity_id=${user.identityId}&imagefile=${faceswapJob.imageFilename}&videofile=${videoFileName}&use_video_collection=${useVideoCollection}&feature=faceswap`)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    logger.error("Error in response", data.error);
                    if (data.error === "Not enough credits") {
                        navigation.navigate('PaymentScreen', { jobId: jobId, price: PRICE_PER_GENERATION, callbackFn: () => startFaceswapJob(jobId) });
                    }
                    setError(data.error);
                }
                logger.debug('Job is created', data);
                setUser({ ...user, balance: user.balance - PRICE_PER_GENERATION });
                setIsJobStarted(false);
                navigation.navigate('JobScreen', { jobId: jobId });
            })
            .catch(error => {
                setIsJobStarted(false);
                setError(error);
                logger.error("Starting a job failed", error);
            });
    }

    const renderItem = ({ item }) => {
        return (
            <View style={styles.videoView}>
                <Pressable onPress={(data) => { selectVideo(item) }}>

                    <Image source={item.uri} style={{ width: styles.video.width, aspectRatio: item.width / item.height }} />
                    {/*
                {item.type === "image/gif" ? (
                    <Image source={item.uri} style={{ width: styles.video.width, aspectRatio: item.width / item.height }} />
                ) : (
                    <CustomVideo
                        source={item.uri}
                        style={{ width: styles.video.width, aspectRatio: item.width / item.height }}
                        controls={false}
                        loop={true}
                        autoPlay={true}
                        muted={true}
                        poster={item?.poster}
                    />
                )}
                */}
                </Pressable>
            </View>

        )
    };


    return (
        <View style={styles.container}>
            <View style={{}}>
                {videoURI ? (
                    <View style={styles.videoPreviewWrapper}>
                        <Pressable onPress={resetVideo}>
                            <View style={styles.changeVideoButton}>
                                <Text style={styles.changeVideoButtonText}>Change Video</Text>
                            </View>
                        </Pressable>


                        {videoFile && videoFile.type === "image/gif" ? (
                            <Image source={videoURI} style={[styles.gifPreview, { aspectRatio: aspectRatio }]} onLoad={handleImageLoad} />
                        ) : (
                            <CustomVideo
                                source={videoURI}
                                poster={videoPoster}
                                controls={false}
                                muted={true}
                                loop={true}
                                autoPlay={true}
                                style={styles.videoPreview}
                                posterStyle={styles.videoPreview}
                            />
                        )}
                    </View>
                ) : (
                    <FileUploader fileType="video" onFileUploadCallback={setUploadedVideo} sessionId={user.username} />
                )}
            </View>
            <View style={styles.infoList}>
                <View style={styles.infoListRow}>
                    <FontAwesome5 name="info-circle" size={isDesktopWeb ? scale(10) : scale(25)} style={{ marginRight: 8 }} color="#fb8500" />
                    <Text style={styles.infoListText}>Videos with a single face get best results</Text>
                </View>
                <View style={styles.infoListRow}>
                    <FontAwesome5 name="info-circle" size={isDesktopWeb ? scale(10) : scale(25)} style={{ marginRight: 8 }} color="#fb8500" />
                    <Text style={styles.infoListText}>You can download a lot of fun videos to use on <Text
                        style={{ color: 'blue' }}
                        onPress={openGiphy}>
                        Giphy
                    </Text></Text>
                </View>
                <View style={styles.infoListRow}>
                    <FontAwesome5 name="hand-point-right" size={isDesktopWeb ? scale(10) : scale(25)} style={{ marginRight: 8 }} color="#457b9d" />
                    <Text style={styles.infoListText}>Or simply choose video from a collection of memes</Text>
                </View>
            </View>

            <Pressable onPress={showVideoGallery}>
                <View style={styles.smallButton}>
                    <Text style={[styles.infoListText, { color: 'white' }]}>Memes</Text>
                </View>
            </Pressable>
            {error && (
                <View style={styles.errorBox}>
                    <Text style={styles.text}>{error}</Text>
                </View>
            )}
            <Modal
                animationType="fade"
                transparent={false}
                visible={modalVisible}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modal}>
                        {/* Close Button */}
                        <Pressable style={styles.closeButtonModal} onPress={() => setModalVisible(false)}>
                            <Text style={styles.closeButtonText}>X</Text>
                        </Pressable>

                        <MasonryList
                            data={faceswapVideoList}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => index.toString()}
                            showsVerticalScrollIndicator={false}
                            // onEndReachedThreshold={0.1}
                            numColumns={isDesktopWeb ? 2 : 1}
                        />
                    </View>
                </View>
            </Modal>


            {videoFile && (
                !isJobStarted ? (
                    <View style={styles.button}>
                        <CustomButton
                            label="Create video"
                            labelStyle={{ fontSize: isDesktopWeb ? scale(6) : scale(20) }}
                            containerStyle={{ width: isDesktopWeb ? scale(60) : scale(200) }}
                            onPress={tryToStartFaceswapJob} />
                    </View>
                ) : (
                    <ActivityIndicator size={scale(20)} style={{ marginTop: verticalScale(10) }} />
                )
            )}
        </View>
    );
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        marginTop: '20@mvs',
        justifyContent: isDesktopWeb ? 'flex-start' : 'center',
        // alignItems: 'center',
    },
    header: {
        marginBottom: '15@mvs',
        alignItems: 'center',
    },
    infoList: {
        alignSelf: 'center',
        marginTop: isDesktopWeb ? '20@mvs' : '25@mvs',
        marginHorizontal: isDesktopWeb ? 0 : '25@ms',
    },
    infoListRow: {
        width: isDesktopWeb ? '100%' : '90%',
        marginBottom: isDesktopWeb ? '8@mvs' : '15@mvs',
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoListText: {
        fontSize: isDesktopWeb ? '8@ms' : '15@ms',
    },
    button: {
        marginTop: '35@mvs',
        alignItems: 'center',
    },
    smallButton: {
        width: isDesktopWeb ? '50@ms' : '90@ms',
        backgroundColor: '#282C34',
        paddingHorizontal: '15@ms',
        paddingVertical: '8@mvs',
        borderRadius: '5@ms',
        alignItems: 'center',
        alignSelf: 'center',
    },
    modalView: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    modalTextStyle: {
        fontSize: '15@ms',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(40,44,52,1)',
    },
    modal: {
        width: isDesktopWeb ? '380@ms' : '320@ms',
        height: '80%',
        backgroundColor: 'white',
        borderRadius: '10@mvs',
        padding: '20@ms',
        alignItems: 'center',
        elevation: 5
    },
    videoView: {
        // width: '100@s',
        // height: '100@mvs',
        // width: '100%',
        // height: '100%',
        margin: '5@mvs',
        justifyContent: 'center',
        alignItems: 'center',
    },
    video: {
        width: isDesktopWeb ? '100@s' : '250@ms',
        // height: '100@mvs'
    },
    closeButtonModal: {
        position: 'absolute',
        top: 10,
        right: 10,
        backgroundColor: '#F5F5F5',
        borderRadius: 20,
        width: isDesktopWeb ? '15@ms' : '15@ms',
        height: isDesktopWeb ? '15@mvs' : '15@mvs',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeButtonVideo: {
        position: 'absolute',
        top: '64@mvs',
        right: '34@ms',
        backgroundColor: '#F5F5F5',
        borderRadius: 20,
        width: isDesktopWeb ? '15@ms' : '25@ms',
        height: isDesktopWeb ? '15@mvs' : '25@mvs',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeButtonText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
        color: '#555',
    },
    changeVideoButton: {
        backgroundColor: '#282C34',
        borderRadius: '5@mvs',
        width: isDesktopWeb ? '100@ms' : '150@ms',
        height: isDesktopWeb ? '15@mvs' : '25@mvs',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: valueFor({ web: '2@mvs', native: '10@mvs' })
    },
    changeVideoButtonText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
        color: 'white',
    },

    videoPreviewWrapper: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    videoPreview: {
        width: isDesktopWeb ? '200@s' : '300@ms',
        maxHeight: isDesktopWeb ? '380@mvs' : '350@mvs',
        alignSelf: 'center',
    },
    gifPreview: {
        width: isDesktopWeb ? '200@s' : '280@s',
        alignSelf: 'center',
    },
    errorBox: {
        marginBottom: isDesktopWeb ? '20@mvs' : 0,
        marginHorizontal: isDesktopWeb ? 0 : '20@ms',
        padding: '10@mvs',
        borderRadius: 10,
        fontSize: '10@mvs',
        backgroundColor: '#FF9494',
    },
    text: {
        fontSize: isDesktopWeb ? '8@mvs' : '10@mvs',
    }
});