import jobsState from '../atoms/jobsState';
import { selectorFamily } from 'recoil';

const jobByIdSelector = selectorFamily({
    key: 'jobById',
    get: (jobId) => ({ get }) => {
        const allJobs = get(jobsState);
        return allJobs.find(job => job.id === jobId);
    }
});

export default jobByIdSelector;