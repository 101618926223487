import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import React, { useEffect } from 'react';
import { scale, verticalScale } from 'react-native-size-matters';

import AccountScreen from './AccountScreen';
import { Analytics } from 'aws-amplify';
import AvatarsScreen from './AvatarsScreen';
import { Dimensions } from 'react-native';
import FaceswapNavigator from './FaceswapNavigator';
import { FontAwesome } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { ScaledSheet } from 'react-native-size-matters';
import UserJobsScreen from './UserJobsScreen';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { isDesktopWeb } from './PlatformTools';
import { useLogger } from './LoggerContext';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';
// import { withAuthenticator } from '@aws-amplify/ui-react-native';
// import { withAuthenticator } from 'aws-amplify-react-native';
import { withAuthenticator } from './CustomAuthenticator';

function getCurrentRouteName(navigationState) {
    if (!navigationState) return null;
    const route = navigationState.routes[navigationState.index];
    if (route.state) {
        // Dive into nested navigators
        return getCurrentRouteName(route.state);
    }
    return route.name;
}

const Tab = createMaterialTopTabNavigator();

function MainMemberScreen({ userAuth }) {
    const [user, setUser] = useRecoilState(userState);
    const navigationRef = React.useRef();
    const [isReady, setIsReady] = React.useState(true);
    const [initialState, setInitialState] = React.useState();
    const [activeTab, setActiveTab] = React.useState('Faceswap'); // default tab
    const logger = useLogger();

    const screenHeight = Dimensions.get('window').height;


    useEffect(() => {
        setUser({
            username: userAuth.username,
            balance: userAuth.balance,
            email: userAuth.email,
            identityId: userAuth.identityId,
        });
    }, []);

    if (!user || !isReady) {
        const style = isDesktopWeb ? styles.webContainer : styles.defaultContainer;
        return (
            <View style={[style, { justifyContent: 'center' }]}>
                <ActivityIndicator size={scale(30)} color="grey" />
            </View>
        )
    }


    return (
        <View style={[isDesktopWeb ? styles.webContainer : styles.defaultContainer, Platform.OS === 'web' ? {} : styles.mobileContainer, { maxHeight: screenHeight }]}>
            {isDesktopWeb && (
                <View style={styles.header}>
                    <Image style={styles.logo} source={require('../assets/logo.png')} />
                </View>
            )}
            <NavigationContainer ref={navigationRef}
                // initialState={initialState}
                onStateChange={(state) => {
                    const currentRouteName = getCurrentRouteName(state);
                    Analytics.record({
                        name: 'tabVisit',
                        attributes: { tab: currentRouteName },
                    });

                }}

            >
                <Tab.Navigator
                    // initialRouteName="Avatars"
                    backBehavior="none"
                    screenOptions={
                        {
                            swipeEnabled: false,
                            animationEnabled: Platform.OS !== 'web',
                            tabBarActiveTintColor: "white",
                            tabBarInactiveTintColor: "#bec1c4",
                            tabBarIndicatorStyle: {
                                backgroundColor: "#ED2647"
                            },
                            tabBarStyle: {
                                backgroundColor: "#282C34"
                            }
                        }
                    }
                >
                    <Tab.Screen name="Faceswap" component={FaceswapNavigator} options={{
                        tabBarLabel: ({ color }) => (
                            <View style={styles.tabLabelBox}>
                                <FontAwesome name="magic" size={styles.topBarIcon.size} color={color} />
                                <Text style={[styles.tabLabel, { color: color }]}>
                                    Faceswap
                                </Text>
                            </View>
                        ),
                        title: "Unycorn - Faceswap"
                    }} />
                    <Tab.Screen name="Avatars" component={AvatarsScreen} options={{
                        tabBarLabel: ({ color }) => (
                            <View style={styles.tabLabelBox}>
                                <MaterialIcons name="face" size={styles.topBarIcon.size} color={color} />
                                <Text style={[styles.tabLabel, { color: color }]}>
                                    Avatars
                                </Text>
                            </View>
                        ),
                        title: "Unycorn - Avatars"
                    }} />
                    <Tab.Screen name="My Art" component={UserJobsScreen} options={{
                        tabBarLabel: ({ color }) => (
                            <View style={styles.tabLabelBox}>
                                <MaterialIcons name="collections" size={styles.topBarIcon.size} color={color} />
                                <Text style={[styles.tabLabel, { color: color }]}>
                                    My Art
                                </Text>
                            </View>
                        ),
                        title: "Unycorn - My Art"
                    }} />
                    <Tab.Screen name="Account" component={AccountScreen} options={{
                        tabBarLabel: ({ color }) => (
                            <View style={styles.tabLabelBox}>
                                <MaterialCommunityIcons name="account-circle-outline" size={styles.topBarIcon.size} color={color} />
                                <Text style={[styles.tabLabel, { color: color }]}>
                                    Account
                                </Text>
                            </View>
                        ),
                        title: "Unycorn - Account"
                    }} />
                </Tab.Navigator>
            </NavigationContainer>
        </View>
    )
}

const styles = ScaledSheet.create({
    header: {
        backgroundColor: '#282C34',
        marginTop: Platform.OS === 'web' ? '10@mvs' : '40@mvs',
        borderBottomColor: '#ED2647',
        borderBottomWidth: 2,
        paddingBottom: isDesktopWeb ? '2@mvs' : '3@mvs',
        paddingRight: '3@ms'
        // height: Platform.OS === 'web' ? '30@mvs' : '55@mvs',
    },
    logo: {
        alignSelf: 'flex-end',
        // marginBottom: 'auto',
        width: isDesktopWeb ? '30@ms' : '50@ms',
        height: 'auto',
        aspectRatio: 1000 / 131,
        resizeMode: 'contain'
    },
    defaultContainer: {
        flex: 1,
    },
    mobileContainer: {
        marginTop: '30@mvs',
    },
    webContainer: {
        flex: 1,
        width: '400@ms',
        alignSelf: 'center',
    },
    tabBarContainer: {
        // paddingVertical: 30,
        // width: '40%',
    },
    tabLabelBox: {
        // backgroundColor: 'grey',
        alignItems: 'center',
        padding: '9@ms',
        // borderRadius: '5@ms',
        // borderWidth: '1@ms',
    },
    tabLabel: {
        fontSize: isDesktopWeb ? '8@ms' : '10@s',
        color: '#d5d9e0',
    },
    topBarIcon: {
        size: isDesktopWeb ? '12@s' : '25@s',
    }
});

export default withAuthenticator(MainMemberScreen);