import { Text, View } from 'react-native';

import { Button } from 'react-native-paper';
import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import { isDesktopWeb } from './PlatformTools';

const CustomButton = ({ label, onPress, containerStyle, buttonStyle, labelStyle, disabled = false }) => {
    const handlePress = () => {
        // onPress action will not happen if the button is disabled
        if (!disabled) {
            onPress();
        }
    };

    return (
        <View style={[styles.buttonBox, containerStyle, disabled ? styles.disabledButtonBox : {}]}>
            <Button
                style={[styles.button, buttonStyle, disabled ? styles.disabledButton : {}]}
                contentStyle={{ borderRadius: 20 }}
                onPress={handlePress}
            >
                <Text style={[styles.buttonLabel, labelStyle, disabled ? styles.disabledButtonLabel : {}]}>{label}</Text>
            </Button>
        </View>
    );
};

const styles = ScaledSheet.create({
    buttonBox: {
        // margin: '10@mvs',
        width: isDesktopWeb ? '100@ms' : '200@ms',
        height: isDesktopWeb ? '25@mvs' : '50@ms',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#282C34',
        backgroundColor: '#22668D',
        borderRadius: isDesktopWeb ? '6@mvs' : '20@mvs',
    },
    button: {
        color: 'white',
        fontSize: isDesktopWeb ? '15@mvs' : '20@ms',
        lineHeight: isDesktopWeb ? 'auto' : '20@mvs',
        borderRadius: '8@mvs',
    },
    buttonLabel: {
        color: 'white',
        fontSize: isDesktopWeb ? '12@ms' : '20@ms',
        lineHeight: isDesktopWeb ? '20@mvs' : '25@mvs'
    },
    // Below are new styles for disabled state
    disabledButtonBox: {
        backgroundColor: '#bbbbbb',
        opacity: 0.5,
    },
    disabledButton: {
        color: '#f2f2f2',
    },
    disabledButtonLabel: {
        color: 'grey',
    },
});

export default CustomButton;
