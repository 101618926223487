import { Dimensions, Platform } from 'react-native';

const screenWidth = Dimensions.get('window').width;
const mobileScreenWidthBreakpoint = 800;
export const isDesktopWeb = Platform.OS === 'web' && screenWidth > mobileScreenWidthBreakpoint;
const platform = isDesktopWeb ? "desktop" : Platform.OS;

export function valueFor({ desktop, mobileWeb, web, native, ios, android, all }) {
    switch (platform) {
        case "android":
            return android || native || all;
        case "ios":
            return ios || native || all;
        case "desktop":
            return desktop || web || all;
        case "web":
            return mobileWeb || web || all;
        default:
            return all;
    }
}