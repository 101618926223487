import * as React from "react";

import { Dimensions, Image, StatusBar, Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import Carousel from 'react-native-reanimated-carousel';
import Constants from 'expo-constants';
import CustomButton from '../CustomButton';
import { ScaledSheet } from 'react-native-size-matters';
import asyncStorage from "../../utils/storage";
import { isDesktopWeb } from '../PlatformTools';

export default function IntroStep({ navigation }) {
    const width = Dimensions.get('window').width;

    const images = [
        { id: '1', source: require('../../assets/avatars/examples/cyber1.png'), title: "Cyberpunk" },
        { id: '2', source: require('../../assets/avatars/examples/glamour1.png'), title: "Glamour" },
        { id: '3', source: require('../../assets/avatars/examples/professional1.png'), title: "Professional" },
        { id: '4', source: require('../../assets/avatars/examples/retro3.png'), title: "Retro" },
        { id: '5', source: require('../../assets/avatars/examples/games2.png'), title: "Video games" },
        { id: '6', source: require('../../assets/avatars/examples/retro2.png'), title: "Classic" },
    ];

    const PAGE_WIDTH = window.width;

    return (
        <View style={styles.container}>
            <Carousel
                loop
                width={isDesktopWeb ? scale(150) : scale(250)}
                height={isDesktopWeb ? verticalScale(200) : verticalScale(350)}
                autoPlay={true}
                data={images}
                style={{
                    // width: scale(200),
                    // height: verticalScale(300),
                    justifyContent: "center",
                    alignItems: "center",
                    borderWidth: 0,
                }}

                scrollAnimationDuration={700}
                snapEnabled={true}
                renderItem={({ index }) => (
                    <View
                        style={{
                            // flex: 1,
                            borderWidth: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View style={styles.imageTitleView}>
                            <Text style={styles.imageTitle}>{images[index].title}</Text>
                        </View>
                        <Image
                            source={images[index].source}
                            style={styles.image}
                        />
                    </View>
                )}
            // customAnimation={animationStyle}
            />
            <View style={styles.infoBox}>
                <View style={styles.descriptionView}>
                    <Text style={styles.description}>Turn your photos into wonderful avatars. 10 styles superheros, fantasy, sci-fi, anime and many others.</Text>
                </View>
                <CustomButton label="Start" onPress={() => navigation.navigate("Gender")} />
            </View>
        </View>
    )
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        justifyContent: isDesktopWeb ? 'flex-start' : 'center',
        alignItems: 'center',

    },
    descriptionView: {
        marginTop: '5@mvs',
        marginBottom: '10@mvs',
        marginHorizontal: isDesktopWeb ? '40@ms' : '18@ms',
    },
    description: {
        fontSize: isDesktopWeb ? '10@ms' : '20@ms',
        color: 'black'
    },
    scrollView: {
        flexDirection: 'row',
    },
    imageContainer: {
        // paddingHorizontal: 10,
        alignItems: 'center',
    },
    image: {
        width: isDesktopWeb ? '150@s' : '250@s',
        height: isDesktopWeb ? '200@vs' : '350@vs',
        borderRadius: 10,
    },
    imageTitleView: {
        marginTop: isDesktopWeb ? '10@mvs' : '20@mvs',

    },
    imageTitle: {
        margin: isDesktopWeb ? '5@mvs' : '10@mvs',
        fontSize: isDesktopWeb ? '15@ms' : '20@ms',
        color: '#282C34',
    },
    infoBox: {
        marginTop: '20@mvs',
        alignItems: 'center',
        alignSelf: 'center',
    }

});