/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      identityId
      balance
      totalTransactions
      totalGenerations
      totalFailed
      totalPaid
      jobs {
        items {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      identityId
      balance
      totalTransactions
      totalGenerations
      totalFailed
      totalPaid
      jobs {
        items {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      identityId
      balance
      totalTransactions
      totalGenerations
      totalFailed
      totalPaid
      jobs {
        items {
          id
          userId
          feature
          args
          status
          progress
          currentStep
          totalSteps
          outputLoc
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAIJob = /* GraphQL */ `
  mutation CreateAIJob(
    $input: CreateAIJobInput!
    $condition: ModelAIJobConditionInput
  ) {
    createAIJob(input: $input, condition: $condition) {
      id
      userId
      feature
      args
      status
      progress
      currentStep
      totalSteps
      outputLoc
      payload
      info
      createdAt
      user {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      runpodJobs {
        items {
          id
          aiJobId
          userId
          feature
          args
          status
          progress
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAIJob = /* GraphQL */ `
  mutation UpdateAIJob(
    $input: UpdateAIJobInput!
    $condition: ModelAIJobConditionInput
  ) {
    updateAIJob(input: $input, condition: $condition) {
      id
      userId
      feature
      args
      status
      progress
      currentStep
      totalSteps
      outputLoc
      payload
      info
      createdAt
      user {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      runpodJobs {
        items {
          id
          aiJobId
          userId
          feature
          args
          status
          progress
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAIJob = /* GraphQL */ `
  mutation DeleteAIJob(
    $input: DeleteAIJobInput!
    $condition: ModelAIJobConditionInput
  ) {
    deleteAIJob(input: $input, condition: $condition) {
      id
      userId
      feature
      args
      status
      progress
      currentStep
      totalSteps
      outputLoc
      payload
      info
      createdAt
      user {
        id
        email
        identityId
        balance
        totalTransactions
        totalGenerations
        totalFailed
        totalPaid
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      runpodJobs {
        items {
          id
          aiJobId
          userId
          feature
          args
          status
          progress
          payload
          info
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRunpodJob = /* GraphQL */ `
  mutation CreateRunpodJob(
    $input: CreateRunpodJobInput!
    $condition: ModelRunpodJobConditionInput
  ) {
    createRunpodJob(input: $input, condition: $condition) {
      id
      aiJobId
      userId
      feature
      args
      status
      progress
      payload
      info
      aiJob {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRunpodJob = /* GraphQL */ `
  mutation UpdateRunpodJob(
    $input: UpdateRunpodJobInput!
    $condition: ModelRunpodJobConditionInput
  ) {
    updateRunpodJob(input: $input, condition: $condition) {
      id
      aiJobId
      userId
      feature
      args
      status
      progress
      payload
      info
      aiJob {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRunpodJob = /* GraphQL */ `
  mutation DeleteRunpodJob(
    $input: DeleteRunpodJobInput!
    $condition: ModelRunpodJobConditionInput
  ) {
    deleteRunpodJob(input: $input, condition: $condition) {
      id
      aiJobId
      userId
      feature
      args
      status
      progress
      payload
      info
      aiJob {
        id
        userId
        feature
        args
        status
        progress
        currentStep
        totalSteps
        outputLoc
        payload
        info
        createdAt
        user {
          id
          email
          identityId
          balance
          totalTransactions
          totalGenerations
          totalFailed
          totalPaid
          createdAt
          updatedAt
          owner
          __typename
        }
        runpodJobs {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
