import AsyncStorage from '@react-native-async-storage/async-storage';

const _storeData = async (key, value) => {
    try {
        await AsyncStorage.setItem(
            '@UnycornStore:' + key,
            value,
        );
    } catch (error) {
        console.log(error);
    }
};

const _retrieveData = async (key) => {
    try {
        const value = await AsyncStorage.getItem('@UnycornStore:' + key);
        if (value !== null) {
            // console.log("Retrieved: " + key, value);
        }
        return value;
    } catch (error) {
        console.log(error);
    }
};

const _removeData = async (key) => {
    try {
        await AsyncStorage.removeItem('@UnycornStore:' + key);
    } catch (error) {
        console.log(error);
    }
};


const asyncStorage = {
    storeData: _storeData,
    retrieveData: _retrieveData,
    remove: _removeData,
}

export default asyncStorage;