import React, { useEffect } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import CustomButton from '../CustomButton';
import { ScaledSheet } from 'react-native-size-matters';
import avatarState from '../../atoms/avatarState';
import { isDesktopWeb } from '../PlatformTools';
import { useRecoilState } from 'recoil';

export default function GenderSelectionStep({ navigation }) {
    const [avatarProps, setAvatarProps] = useRecoilState(avatarState);

    const handleSelectGender = (gender) => {
        setAvatarProps({ ...avatarProps, gender: gender });
    };

    return (
        <View style={styles.container}>
            <Text style={styles.header}>Select Your Gender</Text>
            <View style={styles.options}>
                <TouchableOpacity
                    activeOpacity={1}
                    style={avatarProps.gender === 'female' ? styles.selectedButton : styles.button}
                    onPress={() => handleSelectGender('female')}
                >
                    <Text style={styles.buttonText}>Female</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    activeOpacity={1}
                    style={avatarProps.gender === 'male' ? styles.selectedButton : styles.button}
                    onPress={() => handleSelectGender('male')}
                >
                    <Text style={styles.buttonText}>Male</Text>
                </TouchableOpacity>
                {/*
                <TouchableOpacity
                    activeOpacity={1}
                    style={avatarProps.gender === 'non-binary' ? styles.selectedButton : styles.button}
                    onPress={() => handleSelectGender('non-binary')}
                >
                    <Text style={styles.buttonText}>Non-Binary</Text>
                </TouchableOpacity>
                */}
            </View>

            <CustomButton label="Next" onPress={() => navigation.navigate("Upload Images")} />
        </View>
    )
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        justifyContent: isDesktopWeb ? 'flex-start' : 'center',
        alignItems: 'center',
    },
    header: {
        marginTop: isDesktopWeb ? '20@mvs' : 0,
        fontSize: isDesktopWeb ? '18@ms' : '25@ms',
    },
    options: {
        marginTop: '50@mvs',
        marginBottom: '50@mvs',
    },
    button: {
        width: isDesktopWeb ? '80@s' : '180@s',
        backgroundColor: '#DDDDDD',
        padding: 10,
        marginBottom: 10,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#CCCCCC',
    },
    selectedButton: {
        backgroundColor: '#E9C581',
        padding: 10,
        marginBottom: 10,
        borderRadius: 10,
        borderWidth: 1,
    },
    buttonText: {
        fontSize: isDesktopWeb ? '8@vs' : '15@vs',
        color: 'black',
        textAlign: 'center',
    },
    selectedText: {
        // fontSize: 18,
        // marginTop: 20,
    },
});

