import * as Progress from 'react-native-progress';

import React, { useEffect } from 'react';
import { Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';
import { useRecoilState, useRecoilValue } from 'recoil';

import AvatarResultScreen from './AvatarResultScreen';
import CustomButton from './CustomButton';
import FaceswapResultScreen from './faceswap/FaceswapResultScreen';
import { Image } from 'expo-image';
import { ScaledSheet } from 'react-native-size-matters';
import { isDesktopWeb } from './PlatformTools';
import jobByIdSelector from '../selectors/jobByIdSelector';
import { useLogger } from './LoggerContext';
import userState from '../atoms/userState';

export default function JobScreen({ route, navigation }) {
    const [user, setUser] = useRecoilState(userState);
    const [error, setError] = React.useState(null);
    const jobId = route.params.jobId;
    const job = useRecoilValue(jobByIdSelector(jobId));
    const logger = useLogger();


    useEffect(() => {
        //find the job with jobId, starting from the last one
        logger.debug("Current job", job);
        if (job) {
            logger.debug("Updating job progress", job.progress);
            if (job.status === "FAILED") {
                setError(job.info || "Job has failed. Please try again later. If error persits please reach out support@unycorn.app");
            }
        }
    }, [job]);


    if (job && job.status === "COMPLETED") {
        logger.debug("Job is completed. Rendering result screen", job);

        if (job.feature === "avatars") {
            return (
                <AvatarResultScreen job={job} navigation={navigation} />
            )
        } else if (job.feature === "faceswap") {
            return (
                <FaceswapResultScreen job={job} navigation={navigation} />
            )
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <Image source={require('../assets/robot-avatar.png')} style={styles.headerImage} />
                <View style={styles.progressInfo}>
                    <Text style={styles.textHeader}>Tiny robots are working on your task</Text>
                    {job.feature === "faceswap" || job?.progress < 0.8 ? (
                        <Text style={styles.textSmall}>The job may take {job.feature === "avatars" ? "50-60" : "5-10"} minutes or longer depending on the current load</Text>
                    ) : (
                        <Text style={styles.textSmall}>Almost there, generating {job.feature === "avatars" ? "images" : "video"}. Hang tight!</Text>
                    )}
                    {!error && (
                        <>
                            <Progress.Bar progress={job?.progress || 0} width={scale(60)} height={verticalScale(7)} style={styles.progressBar} color="#1167b1" indeterminate={job.feature === 'faceswap'} />
                            <View style={styles.mainView}>
                                <Text style={styles.text}>🚀 Setting the stage, prepping our pixel palette, and firing up those GPU engines! Behind every great art piece is a tiny wait. Hang tight ❤️ </Text>
                            </View>
                        </>
                    )}
                </View>
                {error && (
                    <View>
                        <View style={styles.errorBox}>
                            <Text style={styles.text}>{error}</Text>
                        </View>
                        <CustomButton
                            label={job.feature === 'avatars' ? "Create new avatars" : "Create new video"}
                            containerStyle={{ marginTop: isDesktopWeb ? verticalScale(10) : verticalScale(10), alignSelf: 'center' }}
                            labelStyle={{ fontSize: isDesktopWeb ? scale(5) : scale(15) }}
                            onPress={() => navigation.navigate(job.feature === "avatars" ? "AvatarIntro" : "FaceswapIntro")} />
                    </View>

                )}
            </View>
        </View>
    );
}

const styles = ScaledSheet.create({
    container: {
        flex: 1
    },
    content: {
        alignItems: 'center',
    },
    result: {

    },
    errorBox: {
        marginBottom: '25@mvs',
        marginHorizontal: '10@ms',
        padding: '10@mvs',
        borderRadius: 10,
        fontSize: '10@mvs',
        backgroundColor: '#FF9494',
    },
    progressInfo: {
        alignItems: 'center',
        margin: '20@ms',
    },
    progressBar: {
        margin: '10@mvs',
        width: isDesktopWeb ? '90@ms' : '120@ms',
    },
    headerImage: {
        width: '100%',
        height: isDesktopWeb ? '210@mvs' : '220@mvs',
        resizeMode: 'cover',
    },
    mainView: {
        marginTop: '15@mvs',
    },
    textHeader: {
        fontSize: isDesktopWeb ? '15@mvs' : '25@mvs',
        marginTop: isDesktopWeb ? '2@mvs' : '5@mvs',
        marginBottom: isDesktopWeb ? '2@mvs' : '8@mvs',
        textAlign: 'center'

    },
    textSmall: {
        fontSize: isDesktopWeb ? '8@mvs' : '15@mvs',
        color: 'grey'
    },
    text: {
        fontSize: isDesktopWeb ? '8@mvs' : '20@ms',
    }
})