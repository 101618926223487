import { Auth, Hub } from 'aws-amplify';
import { Authenticator, SignIn } from 'aws-amplify-react-native';
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import { AntDesign } from '@expo/vector-icons';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ScaledSheet } from 'react-native-size-matters';
import { isDesktopWeb } from './PlatformTools';

export { withAuthenticator } from 'aws-amplify-react-native';


function CustomAuthenticator() {
    return (
        <View style={styles.container}>
            <View style={styles.authContainer}>
                <View style={styles.socialLoginView}>
                    <Pressable onPress={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>
                        <View style={[styles.socialLoginButton, { backgroundColor: '#DB4437' }]}>
                            <AntDesign name="google" size={styles.socialButtonIcon.size} color="white" />
                            <Text style={styles.socialLoginButtonText}>Continue with Google</Text>
                        </View>
                    </Pressable>

                    <Pressable onPress={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple })}>
                        <View style={[styles.socialLoginButton, { backgroundColor: 'black' }]}>
                            <AntDesign name="apple1" size={styles.socialButtonIcon.size} color="white" />
                            <Text style={styles.socialLoginButtonText}>Continue with Apple</Text>
                        </View>
                    </Pressable>
                </View>

                <Authenticator
                    theme={customTheme}
                    socialProviders={['google', 'apple', 'facebook']}
                    signUpConfig={{
                        hiddenDefaults: ['phone_number', 'email'],
                        signUpFields: [
                            {
                                label: 'Email',
                                key: 'username',
                                required: true,
                                type: 'email',
                                displayOrder: 1,
                            },
                            {
                                label: 'Password',
                                key: 'password',
                                required: true,
                                type: 'password',
                                displayOrder: 2
                            },
                        ]
                    }}
                />
            </View>
        </View>
    )
}

export const deepSquidInk = '#152939';
export const linkUnderlayColor = '#FFF';
export const textInputColor = '#000000';
export const textInputBorderColor = '#C4C4C4';
export const placeholderColor = '#C7C7CD';
export const buttonColor = '#ff9900';
export const disabledButtonColor = '#ff990080';

const styles = ScaledSheet.create({
    container: {
        flex: 1,
    },
    authContainer: {
        borderRadius: '5@ms',
        borderWidth: Platform.OS === 'web' ? '1@ms' : 0,
        borderColor: 'gray',
        padding: '10@ms',
        justifyContent: 'center',
        height: isDesktopWeb ? '50%' : '50%',
        marginTop: Platform.OS === 'web' ? 0 : '100@mvs',
        // margin: '20@ms',
    },
    socialLoginView: {
        alignItems: 'center',
        // width: '150@ms'

    },
    socialLoginButton: {
        flexDirection: 'row',
        padding: isDesktopWeb ? '5@ms' : '10@ms',
        paddingLeft: isDesktopWeb ? '20@ms' : '50@ms',
        marginVertical: '3@mvs',
        borderRadius: isDesktopWeb ? '3@ms' : '5@ms',
        alignItems: 'center',
        width: isDesktopWeb ? '130@ms' : (Platform.OS === 'web' ? '260@ms' : '290@ms'),
        // justifyContent: 'center',
    },
    socialButtonIcon: {
        size: isDesktopWeb ? '10@ms' : '15@ms',

    },
    socialLoginButtonText: {
        color: 'white',
        fontSize: isDesktopWeb ? '7@ms' : '15@ms',
        marginLeft: '5@ms'
    }
});

const customTheme = ScaledSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'space-around',
        paddingTop: 20,
        width: '100%',
        backgroundColor: '#282C34',
    },
    section: {
        flex: 1,
        width: '100%',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
    },
    sectionScroll: {
        flex: 1,
        width: '100%',
        paddingHorizontal: 20,
    },
    sectionHeader: {
        width: '100%',
        marginBottom: 32,
        paddingTop: 20,
    },
    sectionHeaderText: {
        color: 'white',
        fontSize: 20,
        fontWeight: '500',
    },
    sectionFooter: {
        width: '100%',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 20,
    },
    sectionFooterLink: {
        fontSize: 16,
        color: buttonColor,
        alignItems: 'baseline',
        textAlign: 'center',
    },
    sectionFooterLinkDisabled: {
        fontSize: 14,
        color: disabledButtonColor,
        alignItems: 'baseline',
        textAlign: 'center',
    },
    navBar: {
        marginTop: 35,
        padding: 15,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    navButton: {
        marginLeft: 12,
        borderRadius: 4,
    },
    cell: {
        flex: 1,
        width: '50%',
    },
    errorRow: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    errorRowIcon: {
        height: 25,
        width: 25,
    },
    errorRowText: {
        marginLeft: 10,
        color: 'white'
    },
    photo: {
        width: '100%',
    },
    album: {
        width: '100%',
    },
    button: {
        backgroundColor: buttonColor,
        alignItems: 'center',
        padding: 16,
    },
    buttonDisabled: {
        backgroundColor: disabledButtonColor,
        alignItems: 'center',
        padding: 16,
    },
    buttonText: {
        color: 'black',
        fontSize: 14,
        fontWeight: '600',
    },
    formField: {
        marginBottom: isDesktopWeb ? '15@mvs' : '20@mvs',
    },
    input: {
        padding: 16,
        borderWidth: 1,
        borderRadius: 3,
        borderColor: textInputBorderColor,
        color: 'white'
    },
    inputLabel: {
        marginBottom: 8,
        color: 'white'
    },
    linkUnderlay: {
        color: linkUnderlayColor,
    },
    phoneContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    phoneInput: {
        flex: 2,
        padding: 16,
        borderWidth: 1,
        borderRadius: 3,
        borderColor: textInputBorderColor,
        color: 'white'
    },
    picker: {
        flex: 1,
        height: 44,
        // ensure that longer text values render without truncation
        // as the selected value of the Picker on Android
        minWidth: Platform.OS === 'android' ? 16 : 0,
        backgroundColor: '#282C34',
        borderColor: textInputBorderColor,
        borderWidth: 1,
        borderRadius: 3,
        color: 'white'
    },
    pickerItem: {
        height: 44,
        backgroundColor: '#282C34',
        color: 'white'
    },
    signedOutMessage: {
        textAlign: 'center',
        padding: 20,
        color: 'white'
    },
});

export default CustomAuthenticator;