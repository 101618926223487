import * as Progress from 'react-native-progress';
import * as subscriptions from '../src/graphql/subscriptions';

import { API, Amplify, Auth, Hub, graphqlOperation } from 'aws-amplify';
import { ActivityIndicator, FlatList, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { isDesktopWeb, valueFor } from './PlatformTools';
import { scale, verticalScale } from 'react-native-size-matters';

import AvatarResultScreen from './AvatarResultScreen';
import Constants from 'expo-constants';
import { Entypo } from '@expo/vector-icons';
import FaceswapResultScreen from './faceswap/FaceswapResultScreen';
import { FontAwesome } from '@expo/vector-icons';
import { Image } from 'expo-image';
import { MaterialIcons } from '@expo/vector-icons';
import { Pressable } from 'react-native';
import React from 'react';
import S3Image from './S3Image';
import { ScaledSheet } from 'react-native-size-matters';
import { Storage } from 'aws-amplify';
import avatarState from '../atoms/avatarState';
import jobsState from '../atoms/jobsState';
import { listJobsByUser } from '../src/graphql/queries';
import { timeAgo } from '../utils/formatters';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';

export default function UserJobsScreen({ navigation }) {
    const [user, setUser] = useRecoilState(userState);
    const [loading, setLoading] = React.useState(true);
    const [jobs, setJobs] = useRecoilState(jobsState);
    const [selectedJob, setSelectedJob] = React.useState(null);
    const [nextToken, setNextToken] = React.useState(null);


    useEffect(() => {
        if (user && user.username) {
            fetchNextBatchOfJobs();
        }
    }, [user?.username]);


    useEffect(() => {
        const subOnCreate = API.graphql(
            graphqlOperation(subscriptions.onCreateAIJob)
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("New job is created", { provider, value });
                const newJob = value.data.onCreateAIJob;
                setJobs(prevJobs => [newJob, ...prevJobs]);
            },
            error: (error) => console.warn(error)
        });

        const subOnUpdate = API.graphql(
            graphqlOperation(subscriptions.onUpdateAIJob)
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("Job is updated", { provider, value });
                const updatedJob = value.data.onUpdateAIJob;
                if (updatedJob.currentStep) {
                    updatedJob.progress = updatedJob.currentStep / updatedJob.totalSteps;
                }
                else {
                    updatedJob.progress = 0;
                }
                if (updatedJob.status === "COMPLETED") {
                    updatedJob.payload = JSON.parse(updatedJob.payload);
                    if (updatedJob.args) {
                        updatedJob.args = JSON.parse(updatedJob.args);
                    }
                    setJobs(prevJobs =>
                        prevJobs.map(job =>
                            job.id === updatedJob.id ? updatedJob : job
                        )
                    );
                } else {
                    setJobs(prevJobs =>
                        prevJobs.map(job =>
                            job.id === updatedJob.id ? updatedJob : job
                        )
                    );
                }
            },
            error: (error) => console.warn(error)
        });



        return () => {
            subOnCreate.unsubscribe();
            subOnUpdate.unsubscribe();
        };
    }, []);

    function resetSelectedJob() {
        setSelectedJob(null);
    }

    function fetchNextBatchOfJobs() {
        fetchJobsForUser(user.username, nextToken).then(processedJobs => {
            console.log(processedJobs);
            setJobs(prevJobs => [...prevJobs, ...processedJobs]);
            setLoading(false);
        }).catch(err => {
            console.log("ERROR", err);
            setLoading(false);
        });
    }

    const fetchJobsForUser = async (username, nextToken) => {
        const res = await API.graphql(graphqlOperation(listJobsByUser, {
            userId: username, limit: 5, sortDirection: 'DESC', nextToken: nextToken
        }));
        const originalJobs = res.data.listJobsByUser.items;
        setNextToken(res.data.listJobsByUser.nextToken);

        return await processJobs(originalJobs);
    };

    const processJobs = async (jobs) => {
        const processedJobs = [];

        for (let job of jobs) {
            let processedJob = {
                ...job,
                payload: JSON.parse(job.payload),
                progress: job.currentStep ? job.currentStep / job.totalSteps : undefined,
                idx: jobs.indexOf(job)
            };

            if (processedJob.args) {
                processedJob.args = JSON.parse(processedJob.args);
            }

            processedJobs.push(processedJob);
        }

        return processedJobs;
    };


    async function handleJobClick(job) {
        console.log("Clicked job", job);
        setSelectedJob(job);
    }

    function deterministicSample(arr, n) {
        if (n <= 0 || n > arr.length) {
            throw new Error("Invalid sample size");
        }

        const step = Math.floor(arr.length / n);
        let sample = [];

        for (let i = 0; i < arr.length; i += step) {
            sample.push(arr[i]);
            if (sample.length === n) {
                break;
            }
        }

        return sample;
    }


    const renderItem = React.useCallback(({ item, index }) => (
        <Pressable onPress={() => handleJobClick(item)} key={index} disabled={item.status !== 'COMPLETED'}>
            <View style={styles.jobView} >
                <View style={styles.jobTitleView}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.jobTitle}>{item.feature == 'avatars' ? 'Avatars' : "Faceswap"}</Text>
                        {
                            item.status === "COMPLETED" ? (
                                <Entypo name="check" size={isDesktopWeb ? scale(10) : scale(25)} color="green" />
                            ) : item.status === "FAILED" ? (
                                <View style={styles.failedStatus}>
                                    <Text style={styles.status}>Failed</Text>
                                </View>
                            ) : (
                                <View style={styles.inProgressStatus}>
                                    <Text style={styles.status}>In Progress</Text>
                                </View>
                            )
                        }
                    </View>
                    {item?.payload?.image_filenames && item.payload.image_filenames.length > 0 && (
                        <Text style={[styles.smallText, styles.smallTextImageCount]}>{item.payload.image_filenames.length} images</Text>
                    )}
                </View>

                <View style={item.feature === 'faceswap' ? styles.imagePreviewFaceswap : styles.imagePreview}>
                    {item.status === "COMPLETED" ? (
                        item.feature === 'faceswap' ? (
                            <>
                                <S3Image source={"faceswap/" + item.id + "/thumbnails/" + item.args.imagefile} style={styles.imageForFaceswap} />
                                <FontAwesome name="plus" size={scale(10)} color="#282C34" style={styles.plusIcon} />
                                <S3Image source={"faceswap/" + item.id + "/thumbnails/preview.jpg"} style={styles.imageForFaceswap} />
                            </>
                        ) : (
                            deterministicSample(item.payload.image_filenames, Math.min(5, item.payload.image_filenames.length)).map((filename, index) => {
                                return (
                                    <S3Image source={"avatars/" + item.id + "/thumbnails/" + filename} key={index} style={styles.image} />
                                )
                            })
                        ))
                        : (
                            <View>
                                {item.progress && item.status != "FAILED" ? (
                                    <>
                                        <Text style={styles.progressIndicator}>{(item.progress * 100).toFixed(0) + '%'}</Text>
                                        <Progress.Bar progress={item.progress} width={scale(80)} height={verticalScale(7)} style={styles.progressBar} color="#1167b1" />
                                    </>
                                ) : (
                                    item.info ? (
                                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <MaterialIcons name="error" size={isDesktopWeb ? scale(8) : scale(17)} color="red" />
                                            <Text style={styles.statusExplanation}>{item.info}</Text>
                                        </View>
                                    ) : (

                                        <Text style={styles.progressIndicator}>Result is not ready yet</Text>
                                    )
                                )}
                            </View>
                        )}
                </View>
                <View>
                    <Text style={[styles.smallText, styles.smallTextTimeAgo, { color: 'grey' }]}>{timeAgo(item.createdAt)}</Text>
                </View>
            </View>
        </Pressable>
    )
    );


    if (selectedJob && selectedJob.status === "COMPLETED") {
        if (selectedJob.feature === "faceswap") {
            return (
                <FaceswapResultScreen job={selectedJob} goBack={resetSelectedJob} navigation={navigation} />
            )
        } else if (selectedJob.feature === "avatars") {
            return (
                <AvatarResultScreen job={selectedJob} goBack={resetSelectedJob} navigation={navigation} />
            )
        }
    }

    if (selectedJob && selectedJob.status !== "COMPLETED") {
        alert("This generation is still in progress. Please check back later.");
    }



    if (loading) {
        return (
            <View style={styles.container}>
                <ActivityIndicator size={scale(30)} color="gray" />
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>Your Creations</Text>
                <Text style={[styles.smallText, { color: 'grey' }]}>All your generations will show up here</Text>
            </View>
            <FlatList
                data={jobs}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                contentContainerStyle={styles.resultView}
                showsVerticalScrollIndicator={false}
                onEndReached={() => {
                    if (nextToken) {
                        fetchNextBatchOfJobs();
                    }
                }}
                onEndReachedThreshold={0.5}
            />
        </View>
    )
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20@mvs',
    },
    header: {
        marginBottom: '15@mvs',
        alignItems: 'center',
    },
    title: {
        color: 'black',
        fontSize: isDesktopWeb ? '12@ms' : '15@ms',
        marginBottom: '5@mvs',
    },
    jobTitle: {
        color: 'black',
        fontSize: isDesktopWeb ? '9@ms' : '15@ms',
        fontWeight: 'bold',
    },
    resultView: {
        padding: 5,
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    jobView: {
        borderWidth: 1,
        borderRadius: '5@ms',
        borderColor: 'gray',
        width: '320@ms',
        // height: '110@ms',
        margin: '5@mvs',
        // padding: '8@mvs',
        justifyContent: 'space-between',
        // backgroundColor: '#e3f2fd'
        backgroundColor: '#e7ecef'
        // backgroundColor: '#e1ecf7'
    },
    jobTitleView: {
        justifyContent: 'flex-start',
        paddingHorizontal: '8@ms',
        paddingTop: '8@mvs',
    },
    smallText: {
        fontSize: isDesktopWeb ? '7@ms' : '12@ms',
        color: 'grey',
    },
    smallTextTimeAgo: {
        paddingLeft: isDesktopWeb ? '8@ms' : '8@ms',
        marginBottom: isDesktopWeb ? '2@mvs' : '1@mvs',
    },
    smallTextImageCount: {
        // paddingBottom: isDesktopWeb ? '2@mvs' : '3@mvs',
    },
    imagePreview: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8@mvs',
    },
    imagePreviewFaceswap: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '8@mvs',
    },
    image: {
        width: '50@ms',
        height: '60@mvs',
        aspectRatio: 1,
        marginRight: '5@ms'
    },
    imageForFaceswap: {
        width: '60@ms',
        height: '60@mvs',
    },
    plusIcon: {
        marginHorizontal: '5@ms'
    },
    progressIndicator: {
        fontWeight: 'bold',
        fontSize: '10@ms',
        color: 'black'
    },
    inProgressStatus: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#FFCC5C',
        paddingHorizontal: '5@ms',
        paddingVertical: '2@mvs',
        borderRadius: '5@ms',
    },
    failedStatus: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#E06469',
        paddingHorizontal: '5@ms',
        paddingVertical: '2@mvs',
        borderRadius: '5@ms',
    },
    status: {
        fontSize: valueFor({ web: '9@ms', ios: '12@ms', android: '15@ms' }),
    },
    statusExplanation: {
        fontSize: valueFor({ web: '8@ms', ios: '12@ms', android: '12@ms' }),
        marginLeft: valueFor({ web: '3@ms', native: '3@ms' }),
    },
    progressBar: {
    },

});

