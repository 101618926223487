import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import * as MediaLibrary from 'expo-media-library';
import * as Progress from 'react-native-progress';

import { Linking, Modal, Platform, Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import { Entypo } from '@expo/vector-icons';
import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import { Storage } from 'aws-amplify';
import { isDesktopWeb } from './PlatformTools';
import { useState } from 'react';

export default function SaveFilesButton({ sourceList, styleButton, styleText }) {
    const [progress, setProgress] = useState(0);
    const [processedCounter, setProcessedCounter] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);


    const downloadFile = async (url, filename) => {
        const downloadResumable = FileSystem.createDownloadResumable(
            url,
            FileSystem.documentDirectory + filename,
            {},
            downloadProgress => {
                // only track progress here if one file needs to be downloaded
                if (sourceList.length == 1) {
                    let progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
                    progress = parseFloat(progress.toFixed(2));
                    setProgress(progress);
                }
            }
        );

        try {
            const result = await downloadResumable.downloadAsync();
            console.log('Finished downloading', result);
            return result.uri;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleSave = async () => {
        if (Platform.OS === "web") {
            handleSaveForWeb();
        } else {
            handleSaveForMobile();
        }
    }

    const handleSaveForWeb = async () => {
        if (sourceList.length == 1) {
            const fileURL = await Storage.get(sourceList[0], { level: "private", expires: 3600 });
            Linking.openURL(fileURL);
        } else {
            const path = sourceList[0];
            const s3key = path.substring(0, path.lastIndexOf('/'));
            const fileURL = await Storage.get(s3key + "/unycorn_avatars.zip", { level: "private", expires: 3600 });
            Linking.openURL(fileURL);
        }
    }

    const handleSaveForMobile = async () => {
        const { status } = await MediaLibrary.requestPermissionsAsync(true);
        if (status !== 'granted') {
            alert('We need permissions to save images and videos!');
            return;
        }

        try {
            // Map each source to a download promise
            setModalVisible(true);
            const downloadPromises = sourceList.map(async (filename, i) => {
                const fileURL = await Storage.get(filename, { level: "private", expires: 3600 });

                const localFilename = filename.split("/").pop();
                const localURI = await downloadFile(fileURL, localFilename);

                const fileInfo = await FileSystem.getInfoAsync(localURI);

                const asset = await MediaLibrary.createAssetAsync(localURI);
                await MediaLibrary.createAlbumAsync('Download', asset, true);
                // const result = await MediaLibrary.saveToLibraryAsync(localURI);
                // console.log("Saved", result);

                // update progress for multi-files downloads
                if (sourceList.length > 1) {
                    setProcessedCounter(counter => {
                        const updatedCounter = counter + 1;
                        setProgress(updatedCounter / sourceList.length);
                        return updatedCounter;
                    });
                }
            });

            // Wait for all downloads to complete
            await Promise.all(downloadPromises);

            alert('Content saved to the gallery!');
        } catch (error) {
            console.error(error);
            alert('There was an error saving the content. Please try again later.');
        } finally {
            setProgress(0);
            setProcessedCounter(0);
            setModalVisible(false);
        }

    };


    return (
        <View>
            <Entypo name="download" onPress={handleSave} style={styleButton} size={isDesktopWeb ? scale(15) : scale(40)} color="black" />
            <View style={styles.centeredView}>
                <Modal
                    animationType="slide"
                    transparent={false}
                    visible={modalVisible}
                >
                    <View style={styles.centeredView}>
                        <View style={[styles.modal, styles.downloadProgress]}>
                            <Text style={styles.modalTextStyle}>Downloading content...</Text>
                            <Progress.Bar progress={progress} style={styles.downloadProgressBar} />
                        </View>
                    </View>
                </Modal>
            </View>
        </View>
    )
}

const styles = ScaledSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modal: {
        height: '200@vs',
        justifyContent: 'center',
        alignItems: 'center',
    },
    downloadProgress: {
        padding: '20@ms',
        width: '250@s',
        alignSelf: 'center',
        alignItems: 'center',
    },
    downloadProgressBar: {
        // width: '220@s',
        // height: '20@vs',
        alignSelf: 'center',
    },
    modalTextStyle: {
        fontSize: '15@ms',
    }
})