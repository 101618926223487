import { Pressable, Text, View } from 'react-native';
import { isDesktopWeb, valueFor } from './PlatformTools';

import CustomVideo from './CustomVideo';
import { Image } from 'expo-image';
import { ScaledSheet } from 'react-native-size-matters';
import logoPng from '../public/logo.png';

function PublicFrontpage({ setAuthState }) {
    return (
        <View style={styles.container}>
            <View>
                <Image source={require('../assets/unycorn-robot-logo.png')} style={styles.headerImage} />
            </View>
            <View style={styles.pageContent}>
                <Image style={styles.logo} source={logoPng} />
                <Text style={styles.description}>
                    Friendly AI magic for visual content
                </Text>
                <View style={styles.underDescription}>
                    <Text style={styles.underDescriptionText}>
                        No subscription fees
                    </Text>
                </View>
            </View>
            <View style={styles.signInButtonBox}>
                <Text style={styles.heart}>❤️</Text>
                <Pressable onPress={() => setAuthState("signIn")}>
                    <View style={styles.signInButton}>
                        <Text style={styles.signInButtonText}>Start</Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.demoAnimation}>
                <CustomVideo
                    source={require("../assets/unycorn-intro.mp4")}
                    style={styles.videoFrame}
                    controls={false}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                />
            </View>
        </View>
    )
}

export default PublicFrontpage;

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
    },
    backgroundImage: {
        flex: 1, // takes the full height and width
        width: '60@ms',
        resizeMode: 'cover',
    },
    headerImage: {
        marginTop: valueFor({ native: "25@mvs", all: 0 }),
        width: '110@ms',
        aspectRatio: 1,
    },
    pageContent: {
        borderRadius: 20,
        padding: '10@ms',
        alignItems: 'center',
    },
    logo: {
        width: isDesktopWeb ? '100@ms' : '170@ms',
        aspectRatio: 1000 / 131,
        resizeMode: 'contain',
    },
    description: {
        marginTop: isDesktopWeb ? '10@mvs' : '15@mvs',
        textAlign: 'center',
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
        color: '#E7E2D1',
    },
    underDescription: {
        marginTop: isDesktopWeb ? '5@mvs' : '8@mvs',
        borderRadius: isDesktopWeb ? '2@ms' : '5@ms',
        backgroundColor: '#E7E2D1',
        paddingVertical: isDesktopWeb ? '2@mvs' : '5@mvs',
        paddingHorizontal: isDesktopWeb ? '5@ms' : '6@ms',
    },
    underDescriptionText: {
        fontSize: isDesktopWeb ? '7@ms' : '12@ms',
        color: '#282C34',
    },
    signInButtonBox: {
        // paddingTop: '100@mvs',
        // marginBottom: isDesktopWeb ? '20@mvs' : '20@mvs',
        marginBottom: isDesktopWeb ? '10@mvs' : '10@mvs',
    },
    heart: {
        alignSelf: 'center',
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
    },
    signInButton: {
        // marginTop: isDesktopWeb ? '25@mvs' : '30@mvs',
        marginTop: isDesktopWeb ? '10@mvs' : '10@mvs',
        width: isDesktopWeb ? '80@ms' : '180@ms',
        alignItems: 'center',
        padding: '5@ms',
        backgroundColor: '#FAB837',
        borderRadius: isDesktopWeb ? '5@ms' : '10@ms',
        // borderWidth: 2,
        borderColor: 'black',
        justifyContent: 'center'
    },
    signInButtonText: {
        fontSize: isDesktopWeb ? '10@mvs' : '25@mvs',
        fontWeight: 'bold',
        //color: '#282C34',
        color: '#22223b',
    },
    demoAnimation: {
        marginTop: isDesktopWeb ? '10@mvs' : '10@mvs'
    },
    videoFrame: {
        width: valueFor({ desktop: '160@ms', mobileWeb: '230@ms', ios: '230@ms', android: '180@ms' }),
        aspectRatio: 1080 / 1920,
        borderRadius: '10@ms',
    },
});