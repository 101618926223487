/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "f1f98e00c31441cca93891fd4cfe4d2a",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "f1f98e00c31441cca93891fd4cfe4d2a",
            "region": "us-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://tyfsp7o3xbee5dxr7ozdmpdrou.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ofyht4ztnzh2bggpvua5lwgjzy",
    "aws_cognito_identity_pool_id": "us-west-2:6b1d5abb-3672-442d-ba4e-ed3ddf2b57df",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ZP9CYK5EA",
    "aws_user_pools_web_client_id": "55u9ute4pra93cnbg95bvu409b",
    "oauth": {
        "domain": "unycorn0f64d6da-0f64d6da-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19007/,https://dev.unycorn.app/,https://unycorn.app/,unycorn://,exp://127.0.0.1:19000/--/,app.unycorn.ai://,http://localhost:19006/,unycorn://login/",
        "redirectSignOut": "http://localhost:19007/,http://localhost:19007/,https://dev.unycorn.app/,https://dev.unycorn.app/,unycorn://,exp://127.0.0.1:19000/--/,app.unycorn.ai://,https://unycorn.app/,unycorn://logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "unycorn-user-data100254-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
