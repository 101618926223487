import { Text, View } from 'react-native';

import CustomButton from '../CustomButton';
import CustomVideo from '../CustomVideo.web';
import { FontAwesome } from '@expo/vector-icons';
import { Image } from 'expo-image'
import { ScaledSheet } from 'react-native-size-matters';
import { isDesktopWeb } from '../PlatformTools';
import { scale } from 'react-native-size-matters';

export default function MainFaceswapScreen({ navigation }) {
    return (
        <View style={styles.container}>
            <View style={styles.explanation}>
                <View style={{ alignItems: 'center' }}>
                    <Text style={styles.explanationText}>Your photo 🤑</Text>
                    <Image style={styles.explanationImage} source={require('../../assets/faceswap/musk1-512x512.jpg')} />
                </View>
                <View style={styles.plusIcon}>
                    <FontAwesome name="plus" size={isDesktopWeb ? scale(20) : scale(30)} color="#282C34" />
                </View>
                <View style={{ alignItems: 'center' }}>
                    <Text style={styles.explanationText}>Any video or GIF</Text>
                    {/* <CustomVideo source={require('../../assets/faceswap/InfamousWearyIrukandjijellyfish.mp4')} style={styles.explanationVideo} controls={false} autoPlay={true} loop={true} /> */}
                    <Image source={require('../../assets/faceswap/imfine.gif')} style={styles.explanationVideo} />
                </View>
            </View>
            <View style={styles.transitionStep}>
                <Text style={styles.transitionStepText}>🪄</Text>
            </View>
            <View style={styles.demo}>
                {/* <CustomVideo source={require('../../assets/musk_is_fine.mp4')} style={styles.demoVideo} loop={true} controls={isDesktopWeb} autoPlay={true} /> */}
                <Image source={require('../../assets/faceswap/musk_is_fine.gif')} style={styles.demoVideo} />
            </View>
            <View style={styles.button}>
                <CustomButton
                    label="Start"
                    // buttonStyle={{ padding: scale(10) }}
                    labelStyle={{ fontSize: isDesktopWeb ? scale(7.5) : scale(20) }}
                    onPress={() => navigation.navigate("FacepicUpload")} />
            </View>
        </View>
    );
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        padding: '20@mvs',
        justifyContent: isDesktopWeb ? 'flex-start' : 'center',
    },
    header: {
        alignItems: 'center',
    },
    headerText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
        fontWeight: 'bold',
        color: 'black',
    },
    explanation: {
        // marginHorizontal: isDesktopWeb ? '15@ms' : '5@ms',
        marginTop: '15@mvs',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    explanationImage: {
        width: isDesktopWeb ? '80@ms' : '90@ms',
        aspectRatio: 1,
        borderRadius: '10@mvs',
        marginTop: isDesktopWeb ? '2@mvs' : '2@mvs',
    },
    explanationVideo: {
        width: isDesktopWeb ? '160@ms' : '160@ms',
        aspectRatio: 1280 / 720,
        marginTop: isDesktopWeb ? '2@mvs' : '2@mvs',
    },
    explanationText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
    },
    demo: {
        marginTop: isDesktopWeb ? '20@mvs' : '10@mvs',
        alignItems: 'center',
    },
    demoText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
        color: 'black',
    },
    demoVideo: {
        marginTop: isDesktopWeb ? '3@mvs' : '5@mvs',
        width: isDesktopWeb ? '270@ms' : '320@ms',
        aspectRatio: 1280 / 720,
        resizeMode: 'contain',
    },
    transitionStep: {
        alignItems: 'center',
        marginVertical: isDesktopWeb ? '20@mvs' : '10@mvs',
    },
    transitionStepText: {
        fontSize: isDesktopWeb ? '30@ms' : '60@ms',
    },
    button: {
        marginTop: isDesktopWeb ? '20@mvs' : '35@mvs',
        alignItems: 'center',
    },
    plusIcon: {
        marginTop: isDesktopWeb ? 0 : '15@mvs',
        marginRight: isDesktopWeb ? 0 : '10@ms'
    }
});