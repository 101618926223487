import { API, graphqlOperation } from 'aws-amplify';

import { getUser } from "../src/graphql/queries";

export async function fetchUserAccount(user) {
    try {
        const userData = await API.graphql(graphqlOperation(getUser, { id: user.username }));
        return userData.data.getUser;
    } catch (err) {
        console.log('Error fetching user account:', err);
    }
}