import { Platform, Text, View } from 'react-native';

import { Image } from 'expo-image';
import React from 'react';

export default function CustomVideo({ source, style, controls = true, loop = false, autoPlay = false, muted = true, poster = null, posterStyle = null }) {
    const [aspectRatio, setAspectRatio] = React.useState(1); // Initialize to 1 to avoid division by zero

    const handleLoad = (metaData) => {
        if (metaData && metaData.naturalSize) {
            const { width, height } = metaData.naturalSize;
            if (height !== 0) {
                setAspectRatio(width / height);
            }
        }
    };


    return (
        <video
            src={source}
            controls={controls}
            style={style}
            loop={loop}
            autoPlay={autoPlay}
            muted={muted}
            poster={poster}
            playsInline
            preload="metadata"
        >
            <source src={source} type="video/mp4"></source>
        </video>
    )
}