import { ActivityIndicator, FlatList, Platform, Pressable, Text, View } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import CustomButton from '../CustomButton';
import FileUploader from '../FileUploader';
import { Image } from 'expo-image';
import Ionicons from '@expo/vector-icons/Ionicons';
import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import faceswapJobState from '../../atoms/faceswapJobState';
import { isDesktopWeb } from '../PlatformTools';
import { useRecoilState } from 'recoil';
import userState from '../../atoms/userState';

export default function FacepicUploadScreen({ navigation }) {
    const [user, setUser] = useRecoilState(userState);
    const [faceswapJob, setFaceswapJob] = useRecoilState(faceswapJobState);
    const [imageURI, setImageURI] = React.useState(null);

    function setImage(imageFile) {
        if (imageFile.hasOwnProperty("uri")) {
            setImageURI(imageFile.uri);
        } else {
            setImageURI(URL.createObjectURL(imageFile));
        }
        setFaceswapJob({
            ...faceswapJob,
            imageFilename: imageFile.name,
        });
    }

    function resetImage() {
        setFaceswapJob({});
        setImageURI(null);
    }

    return (
        <View style={styles.container}>
            <View>
                {imageURI ? (
                    <View style={styles.imagePreviewWrapper}>
                        <Image
                            source={{ uri: imageURI }}
                            style={styles.imagePreview}
                        />
                        <Pressable style={styles.closeButton} onPress={resetImage}>
                            <Text style={styles.closeButtonText}>X</Text>
                        </Pressable>
                    </View>
                ) : (
                    <FileUploader fileType="image" onFileUploadCallback={setImage} sessionId={user.username} />
                )}
            </View>
            <View style={styles.infoList}>
                <View style={styles.infoListRow}>
                    <Ionicons name="checkmark-circle" size={isDesktopWeb ? scale(6) : scale(25)} color="green" style={{ marginRight: 8 }} />
                    <Text style={styles.infoListText}>Ensure the face in your photo is clearly visible and directly facing the camera.</Text>
                </View>
                <View style={styles.infoListRow}>
                    <Ionicons name="md-warning" size={isDesktopWeb ? scale(6) : scale(25)} color="#ED2647" style={{ marginRight: 8 }} />
                    <Text style={styles.infoListText}>Using someone else's face in any adult content is strictly prohibited.</Text>
                </View>
            </View>
            {imageURI && (
                <View style={styles.button}>
                    <CustomButton
                        label="Next"
                        labelStyle={{ fontSize: isDesktopWeb ? scale(6) : scale(20) }}
                        containerStyle={{ width: isDesktopWeb ? scale(30) : scale(200) }}
                        onPress={() => navigation.navigate("VideoUpload")} />
                </View>
            )}
        </View>
    );
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        padding: '20@mvs',
        justifyContent: isDesktopWeb ? 'flex-start' : 'center',
    },
    header: {
        marginBottom: '15@mvs',
        alignItems: 'center',
    },
    infoList: {
        alignSelf: 'center',
        marginTop: isDesktopWeb ? '20@mvs' : '25@mvs',
        marginHorizontal: isDesktopWeb ? 0 : '5@ms'
    },
    infoListRow: {
        marginBottom: isDesktopWeb ? '8@mvs' : '15@mvs',
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoListText: {
        fontSize: isDesktopWeb ? '8@ms' : '15@ms',
    },
    button: {
        marginTop: '20@mvs',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: '3@mvs',
        right: isDesktopWeb ? '132@ms' : '39@ms',
        backgroundColor: '#F5F5F5',
        borderRadius: 20,
        width: isDesktopWeb ? '15@ms' : '30@ms',
        height: isDesktopWeb ? '15@mvs' : '30@mvs',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeButtonText: {
        fontSize: isDesktopWeb ? '10@ms' : '24@ms',
        color: '#555',
    },
    imagePreviewWrapper: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imagePreview: {
        width: isDesktopWeb ? '100@ms' : '250@ms',
        height: isDesktopWeb ? '100@mvs' : '250@mvs',
        alignSelf: 'center',
    },

});