import { Image, Text, View } from 'react-native';

import React from 'react';
import { ScaledSheet } from 'react-native-size-matters';
import StripeBuyButton from './StripeBuyButton';
import avatarState from '../atoms/avatarState';
import { fetchUserAccount } from '../utils/user';
import { isDesktopWeb } from './PlatformTools';
import { startAvatarTrainingJob } from '../utils/jobs';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';

export default function PaymentScreen({ route, navigation }) {

    const [avatarProps, setAvatarProps] = useRecoilState(avatarState);
    const [user, setUser] = useRecoilState(userState);
    const [error, setError] = React.useState(null);

    const callbackFn = route?.params?.callbackFn;
    const price = route.params.price;

    // interval for checking user balance
    let intervalId = null;


    useEffect(() => {
        updateUserBalance();
    }, []);


    const updateUserBalance = async () => {
        console.log("Updating user balance");
        fetchUserAccount(user).then((data) => {
            console.log(data);
            setUser({
                ...user,
                balance: data.balance,
                email: data.email,
            });
            if (data.balance >= price) {
                if (intervalId) {
                    clearInterval(intervalId);
                }
                if (callbackFn) {
                    callbackFn();
                } else {
                    startAvatarTrainingJob(user, avatarProps, setAvatarProps).then(job => {
                        if (job.error) {
                            console.log("Error starting job: ", job.error);
                            setError(job.error);
                        } else {
                            console.log("Job started: ", job);
                            setUser({ ...user, balance: user.balance - price });
                            navigation.navigate('JobScreen', { jobId: job.id });
                        }
                    }).catch(error => {
                        console.log("Error starting job: ", error);
                        setError(error);
                    })
                }
            }
        })
    }

    const monitorUserBalance = async () => {
        if (intervalId) clearInterval(intervalId);
        intervalId = setInterval(updateUserBalance, 10000);
    }

    return (
        <View style={styles.container}>
            <Image source={require('../assets/avatars/cyberpunk_payment.png')} style={styles.headerImage} />
            <View style={styles.content}>
                <Text style={styles.headerText}>Just one more step!</Text>
                <Text style={[styles.headerText, styles.headerTextLine2]}>💰 To continue, you'll need some more credits.</Text>
                <View style={styles.balanceView}>
                    <Text style={styles.balanceText}>Current Balance: <Text style={{ fontWeight: 'bold' }}>{user.balance}</Text> credits</Text>
                    <Text style={styles.balanceText}>Cost of Generation: <Text style={{ fontWeight: 'bold' }}>{price}</Text> credits</Text>
                </View>
                <Text style={styles.solutionText}>
                    Unlike other apps, we don't charge you a monthly subscription. Instead, you can buy credits as you need them.
                </Text>
            </View>
            {error && (
                <View style={styles.errorBox}>
                    <Text style={styles.text}>{error}</Text>
                </View>
            )}
            <View style={styles.payment}>
                <StripeBuyButton onPress={() => monitorUserBalance()} />
            </View>
        </View>
    );
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    headerImage: {
        // marginTop: '1@mvs',
        width: '100%',
        height: isDesktopWeb ? '210@mvs' : '220@mvs',
        resizeMode: 'cover',
    },
    content: {
        margin: '25@ms',
    },
    headerText: {
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
    },
    headerTextLine2: {
        marginTop: isDesktopWeb ? '10@mvs' : '10@mvs',
    },
    balanceView: {
        marginVertical: '15@mvs',
        backgroundColor: '#FFCC5C',
        padding: '10@ms',
        borderRadius: '5@ms',
    },
    balanceText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
    },
    solutionText: {
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
    },
    payment: {
        alignItems: 'center',
    },
    errorBox: {
        marginBottom: '20@mvs',
        padding: '10@mvs',
        borderRadius: 10,
        fontSize: '10@mvs',
        backgroundColor: '#FF9494',
    },
})