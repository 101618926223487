export const faceswapVideoList = [
    {
        name: 'rickroll.gif',
        uri: require('../assets/faceswap/rickroll-320.gif'),
        width: 320,
        height: 240,
        type: "image/gif"
    },
    {
        name: 'CheerfulMasculineCobra.gif',
        uri: require('../assets/faceswap/CheerfulMasculineCobra-320.gif'),
        width: 640,
        height: 368,
        poster: require('../assets/faceswap/CheerfulMasculineCobra.png'),
        type: "image/gif"
    },
    {
        name: 'MinorPassionateLcont.gif',
        uri: require('../assets/faceswap/MinorPassionateLcont-320.gif'),
        width: 1080,
        height: 608,
        poster: require('../assets/faceswap/MinorPassionateLcont.png'),
        type: "image/gif"
    },
    {
        name: 'SarcasticPhysicalIberianmole-mobile.gif',
        uri: require('../assets/faceswap/SarcasticPhysicalIberianmole-mobile-320.gif'),
        width: 500,
        height: 230,
        poster: require('../assets/faceswap/SarcasticPhysicalIberianmole-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'WastefulBriefGrebe.gif',
        uri: require('../assets/faceswap/WastefulBriefGrebe-320.gif'),
        width: 430,
        height: 240,
        poster: require('../assets/faceswap/WastefulBriefGrebe.png'),
        type: "image/gif"
    },
    {
        name: 'UnevenOrangeFlyingfish.gif',
        uri: require('../assets/faceswap/UnevenOrangeFlyingfish-320.gif'),
        width: 390,
        height: 226,
        poster: require('../assets/faceswap/UnevenOrangeFlyingfish.png'),
        type: "image/gif"
    },
    {
        name: 'MeekElasticBobolink.gif',
        uri: require('../assets/faceswap/MeekElasticBobolink-320.gif'),
        width: 400,
        height: 224,
        poster: require('../assets/faceswap/MeekElasticBobolink.png'),
        type: "image/gif"
    },
    {
        name: 'DisfiguredLazyErne-mobile.gif',
        uri: require('../assets/faceswap/DisfiguredLazyErne-mobile-320.gif'),
        width: 640,
        height: 434,
        poster: require('../assets/faceswap/DisfiguredLazyErne-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'SophisticatedRelievedAsiaticmouflon-mobile.gif',
        uri: require('../assets/faceswap/SophisticatedRelievedAsiaticmouflon-mobile-320.gif'),
        width: 640,
        height: 558,
        poster: require('../assets/faceswap/SophisticatedRelievedAsiaticmouflon-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'WhoppingAdeptHyrax.gif',
        uri: require('../assets/faceswap/WhoppingAdeptHyrax-320.gif'),
        width: 500,
        height: 318,
        poster: require('../assets/faceswap/WhoppingAdeptHyrax.png'),
        type: "image/gif"
    },
    {
        name: 'BitterWeeHellbender.gif',
        uri: require('../assets/faceswap/BitterWeeHellbender-320.gif'),
        width: 720,
        height: 384,
        poster: require('../assets/faceswap/BitterWeeHellbender.png'),
        type: "image/gif"
    },
    {
        name: 'DefiantTediousHawk-mobile.gif',
        uri: require('../assets/faceswap/DefiantTediousHawk-mobile-320.gif'),
        width: 600,
        height: 338,
        poster: require('../assets/faceswap/DefiantTediousHawk-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'InfamousWearyIrukandjijellyfish.gif',
        uri: require('../assets/faceswap/InfamousWearyIrukandjijellyfish-320.gif'),
        width: 1280,
        height: 720,
        poster: require('../assets/faceswap/InfamousWearyIrukandjijellyfish.png'),
        type: "image/gif"
    },
    {
        name: 'EquatorialPleasedAustralianshelduck.gif',
        uri: require('../assets/faceswap/EquatorialPleasedAustralianshelduck-320.gif'),
        width: 500,
        height: 358,
        poster: require('../assets/faceswap/EquatorialPleasedAustralianshelduck.png'),
        type: "image/gif"
    },
    {
        name: 'SoulfulWeeElephant.gif',
        uri: require('../assets/faceswap/SoulfulWeeElephant-320.gif'),
        width: 1280,
        height: 720,
        poster: require('../assets/faceswap/SoulfulWeeElephant.png'),
        type: "image/gif"
    },
    {
        name: 'DependentTinyAlbino.gif',
        uri: require('../assets/faceswap/DependentTinyAlbino-320.gif'),
        width: 986,
        height: 720,
        poster: require('../assets/faceswap/DependentTinyAlbino.png'),
        type: "image/gif"
    },
    {
        name: 'AmusingMadAlbacoretuna-mobile.gif',
        uri: require('../assets/faceswap/AmusingMadAlbacoretuna-mobile-320.gif'),
        width: 470,
        height: 352,
        poster: require('../assets/faceswap/AmusingMadAlbacoretuna-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'TemptingMilkyFox-mobile.gif',
        uri: require('../assets/faceswap/TemptingMilkyFox-mobile-320.gif'),
        width: 640,
        height: 372,
        poster: require('../assets/faceswap/TemptingMilkyFox-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'ExcitableRecklessDobermanpinscher.gif',
        uri: require('../assets/faceswap/ExcitableRecklessDobermanpinscher-320.gif'),
        width: 998,
        height: 606,
        poster: require('../assets/faceswap/ExcitableRecklessDobermanpinscher.png'),
        type: "image/gif"
    },
    {
        name: 'AnguishedCapitalLeech-mobile.gif',
        uri: require('../assets/faceswap/AnguishedCapitalLeech-mobile-320.gif'),
        width: 640,
        height: 388,
        poster: require('../assets/faceswap/AnguishedCapitalLeech-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'YoungMemorableBlueshark.gif',
        uri: require('../assets/faceswap/YoungMemorableBlueshark-320.gif'),
        width: 920,
        height: 522,
        poster: require('../assets/faceswap/YoungMemorableBlueshark.png'),
        type: "image/gif"
    },
    {
        name: 'ArtisticIndolentLhasaapso.gif',
        uri: require('../assets/faceswap/ArtisticIndolentLhasaapso-320.gif'),
        width: 714,
        height: 700,
        poster: require('../assets/faceswap/ArtisticIndolentLhasaapso.png'),
        type: "image/gif"
    },
    {
        name: 'OptimisticShortAfricangroundhornbill.gif',
        uri: require('../assets/faceswap/OptimisticShortAfricangroundhornbill-320.gif'),
        width: 750,
        height: 422,
        poster: require('../assets/faceswap/OptimisticShortAfricangroundhornbill.png'),
        type: "image/gif"
    },
    {
        name: 'UnlawfulCraftyKarakul-mobile.gif',
        uri: require('../assets/faceswap/UnlawfulCraftyKarakul-mobile-320.gif'),
        width: 640,
        height: 516,
        poster: require('../assets/faceswap/UnlawfulCraftyKarakul-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'HospitableGeneralGazelle-mobile.gif',
        uri: require('../assets/faceswap/HospitableGeneralGazelle-mobile-320.gif'),
        width: 640,
        height: 388,
        poster: require('../assets/faceswap/HospitableGeneralGazelle-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'ClutteredDependentArrowworm.gif',
        uri: require('../assets/faceswap/ClutteredDependentArrowworm-320.gif'),
        width: 1070,
        height: 720,
        poster: require('../assets/faceswap/ClutteredDependentArrowworm.png'),
        type: "image/gif"
    },
    {
        name: 'b86bfdbe6ec960a8305c0d383f13b98e.gif',
        uri: require('../assets/faceswap/b86bfdbe6ec960a8305c0d383f13b98e-320.gif'),
        width: 720,
        height: 1440,
        poster: require('../assets/faceswap/b86bfdbe6ec960a8305c0d383f13b98e.png'),
        type: "image/gif"
    },
    {
        name: 'OrganicClumsyArcticfox.gif',
        uri: require('../assets/faceswap/OrganicClumsyArcticfox-320.gif'),
        width: 1000,
        height: 562,
        poster: require('../assets/faceswap/OrganicClumsyArcticfox.png'),
        type: "image/gif"
    },
    {
        name: 'AlarmedGrandioseBangeltiger-mobile.gif',
        uri: require('../assets/faceswap/AlarmedGrandioseBangeltiger-mobile-320.gif'),
        width: 498,
        height: 280,
        poster: require('../assets/faceswap/AlarmedGrandioseBangeltiger-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'PoliticalWeepyArmyant-mobile.gif',
        uri: require('../assets/faceswap/PoliticalWeepyArmyant-mobile-320.gif'),
        width: 640,
        height: 360,
        poster: require('../assets/faceswap/PoliticalWeepyArmyant-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'ChiefPlainDeinonychus.gif',
        uri: require('../assets/faceswap/ChiefPlainDeinonychus-320.gif'),
        width: 858,
        height: 790,
        poster: require('../assets/faceswap/ChiefPlainDeinonychus.png'),
        type: "image/gif"
    },
    {
        name: 'AcclaimedBestBarb.gif',
        uri: require('../assets/faceswap/AcclaimedBestBarb-320.gif'),
        width: 500,
        height: 290,
        poster: require('../assets/faceswap/AcclaimedBestBarb.png'),
        type: "image/gif"
    },
    {
        name: 'CarelessEvergreenDunlin.gif',
        uri: require('../assets/faceswap/CarelessEvergreenDunlin-320.gif'),
        width: 480,
        height: 270,
        poster: require('../assets/faceswap/CarelessEvergreenDunlin.png'),
        type: "image/gif"
    },
    {
        name: 'TenseElementaryGerenuk.gif',
        uri: require('../assets/faceswap/TenseElementaryGerenuk-320.gif'),
        width: 500,
        height: 778,
        poster: require('../assets/faceswap/TenseElementaryGerenuk.png'),
        type: "image/gif"
    },
    {
        name: 'ConfusedSlushyDuckbillplatypus-mobile.gif',
        uri: require('../assets/faceswap/ConfusedSlushyDuckbillplatypus-mobile-320.gif'),
        width: 640,
        height: 398,
        poster: require('../assets/faceswap/ConfusedSlushyDuckbillplatypus-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'CostlyOilyJellyfish.gif',
        uri: require('../assets/faceswap/CostlyOilyJellyfish-320.gif'),
        width: 480,
        height: 270,
        poster: require('../assets/faceswap/CostlyOilyJellyfish.png'),
        type: "image/gif"
    },
    {
        name: 'PeskyPlayfulHarpyeagle.gif',
        uri: require('../assets/faceswap/PeskyPlayfulHarpyeagle-320.gif'),
        width: 960,
        height: 540,
        poster: require('../assets/faceswap/PeskyPlayfulHarpyeagle.png'),
        type: "image/gif"
    },
    {
        name: 'AgreeableAdventurousFrillneckedlizard-mobile.gif',
        uri: require('../assets/faceswap/AgreeableAdventurousFrillneckedlizard-mobile-320.gif'),
        width: 640,
        height: 386,
        poster: require('../assets/faceswap/AgreeableAdventurousFrillneckedlizard-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'GoldenNextAlaskankleekai.gif',
        uri: require('../assets/faceswap/GoldenNextAlaskankleekai-320.gif'),
        width: 1280,
        height: 720,
        poster: require('../assets/faceswap/GoldenNextAlaskankleekai.png'),
        type: "image/gif"
    },
    {
        name: 'UnevenCapitalFeline-mobile.gif',
        uri: require('../assets/faceswap/UnevenCapitalFeline-mobile-320.gif'),
        width: 640,
        height: 422,
        poster: require('../assets/faceswap/UnevenCapitalFeline-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'SpectacularIncompatibleAsianlion.gif',
        uri: require('../assets/faceswap/SpectacularIncompatibleAsianlion-320.gif'),
        width: 220,
        height: 220,
        poster: require('../assets/faceswap/SpectacularIncompatibleAsianlion.png'),
        type: "image/gif"
    },
    {
        name: 'FocusedSoggyGallinule.gif',
        uri: require('../assets/faceswap/FocusedSoggyGallinule-320.gif'),
        width: 500,
        height: 234,
        poster: require('../assets/faceswap/FocusedSoggyGallinule.png'),
        type: "image/gif"
    },
    {
        name: 'BruisedElderlyDrongo-mobile.gif',
        uri: require('../assets/faceswap/BruisedElderlyDrongo-mobile-320.gif'),
        width: 640,
        height: 482,
        poster: require('../assets/faceswap/BruisedElderlyDrongo-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'NimbleEquatorialIslandwhistler-mobile.gif',
        uri: require('../assets/faceswap/NimbleEquatorialIslandwhistler-mobile-320.gif'),
        width: 640,
        height: 424,
        poster: require('../assets/faceswap/NimbleEquatorialIslandwhistler-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'KaleidoscopicLeanBlackrussianterrier-mobile.gif',
        uri: require('../assets/faceswap/KaleidoscopicLeanBlackrussianterrier-mobile-320.gif'),
        width: 640,
        height: 466,
        poster: require('../assets/faceswap/KaleidoscopicLeanBlackrussianterrier-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'HiddenObviousEuropeanfiresalamander-mobile.gif',
        uri: require('../assets/faceswap/HiddenObviousEuropeanfiresalamander-mobile-320.gif'),
        width: 640,
        height: 378,
        poster: require('../assets/faceswap/HiddenObviousEuropeanfiresalamander-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'EsteemedBlaringDove.gif',
        uri: require('../assets/faceswap/EsteemedBlaringDove-320.gif'),
        width: 500,
        height: 290,
        poster: require('../assets/faceswap/EsteemedBlaringDove.png'),
        type: "image/gif"
    },
    {
        name: 'GleefulMammothDrever.gif',
        uri: require('../assets/faceswap/GleefulMammothDrever-320.gif'),
        width: 474,
        height: 304,
        poster: require('../assets/faceswap/GleefulMammothDrever.png'),
        type: "image/gif"
    },
    {
        name: 'EasyEasyDragonfly-mobile.gif',
        uri: require('../assets/faceswap/EasyEasyDragonfly-mobile-320.gif'),
        width: 640,
        height: 360,
        poster: require('../assets/faceswap/EasyEasyDragonfly-mobile.png'),
        type: "image/gif"
    },
    {
        name: 'BlandMeaslyCob.gif',
        uri: require('../assets/faceswap/BlandMeaslyCob-320.gif'),
        width: 842,
        height: 508,
        poster: require('../assets/faceswap/BlandMeaslyCob.png'),
        type: "image/gif"
    },
    {
        name: 'DiscreteDizzyAiredaleterrier.gif',
        uri: require('../assets/faceswap/DiscreteDizzyAiredaleterrier-320.gif'),
        width: 786,
        height: 540,
        poster: require('../assets/faceswap/DiscreteDizzyAiredaleterrier.png'),
        type: "image/gif"
    },
    {
        name: 'AmusedSlimyEelelephant.gif',
        uri: require('../assets/faceswap/AmusedSlimyEelelephant-320.gif'),
        width: 1000,
        height: 702,
        poster: require('../assets/faceswap/AmusedSlimyEelelephant.png'),
        type: "image/gif"
    },
    {
        name: 'HeavyHeartfeltGroundhog.gif',
        uri: require('../assets/faceswap/HeavyHeartfeltGroundhog-320.gif'),
        width: 500,
        height: 282,
        poster: require('../assets/faceswap/HeavyHeartfeltGroundhog.png'),
        type: "image/gif"
    },
    {
        name: 'PoliteGivingAnophelesmosquito-mobile.gif',
        uri: require('../assets/faceswap/PoliteGivingAnophelesmosquito-mobile-320.gif'),
        width: 640,
        height: 424,
        poster: require('../assets/faceswap/PoliteGivingAnophelesmosquito-mobile.png'),
        type: "image/gif"
    },
];
