import React, { createContext, useContext } from 'react';

import { Logger } from 'aws-amplify';

const LoggerContext = createContext();

export const LoggerProvider = ({ children }) => {
    const logLevel = __DEV__ ? 'DEBUG' : 'INFO';
    const logger = new Logger('Unycorn', logLevel);
    return (
        <LoggerContext.Provider value={logger}>
            {children}
        </LoggerContext.Provider>
    );
};

export const useLogger = () => {
    return useContext(LoggerContext);
};