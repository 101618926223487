import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import * as Progress from 'react-native-progress';

import { ActivityIndicator, Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Camera, CameraType } from 'expo-camera';
import React, { useRef, useState } from 'react';
import { scale, verticalScale } from 'react-native-size-matters';

import { AntDesign } from '@expo/vector-icons';
import { Button } from 'react-native-paper';
import CustomVideo from './CustomVideo.web';
import { Fontisto } from '@expo/vector-icons';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicons from '@expo/vector-icons/Ionicons';
import { ScaledSheet } from 'react-native-size-matters';
import axios from 'axios';
import { getPresignedUrlAndUpload } from '../utils/s3';
import { isDesktopWeb } from './PlatformTools';

function FileUploader({ fileType, onFileUploadCallback, sessionId }) {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(null);
    const [permission, requestPermission] = Platform.OS === 'web' ? useState(null) : Camera.useCameraPermissions();
    const [type, setType] = useState(CameraType.back);
    const cameraRef = useRef(null);

    if (Platform.OS != 'web') {
        if (!permission) {
            console.log("Camera permissions are still loading");
        } else if (!permission.granted) {
            console.log("Requesting camera permissions");
            requestPermission();
        }
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const takePictureAsync = async (fileType) => {
        let result = await ImagePicker.launchCameraAsync({
            mediaTypes: fileType === 'video' ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        // const base64Image = await FileSystem.readAsStringAsync(result.assets[0].uri, {
        // encoding: FileSystem.EncodingType.Base64,
        // });

        const filename = result.assets[0].uri.split('/').pop();
        const fileMimeType = fileType === 'video' ? 'video/mp4, image/gif' : 'image/jpeg';

        const file = {
            name: filename,
            type: fileMimeType,
            uri: result.assets[0].uri,
            // base64: base64Image,
        }

        return file;
    };

    const pickImageAsync = async (fileType) => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: fileType === 'video' ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            // allowsMultipleSelection: true,
            quality: 1,
        });

        // const base64Image = await FileSystem.readAsStringAsync(result.assets[0].uri, {
        // encoding: FileSystem.EncodingType.Base64,
        // });

        const filename = result.assets[0].uri.split('/').pop();
        const fileMimeType = fileType === 'video' ? 'video/mp4, image/gif' : 'image/jpeg';

        const file = {
            name: filename,
            type: fileMimeType,
            uri: result.assets[0].uri,
            // base64: base64Image,
        }

        return file;
    };


    const onCameraMobile = async (fileType) => {
        let res = await takePictureAsync(fileType);

        if (res && !res.canceled) {
            setFile(res);
            setUploading(10);
            getPresignedUrlAndUpload([res], fileType, sessionId, setUploading).then(() => {
                setUploading(false);
                onFileUploadCallback(res);
            });
        }

    };

    const onImagePickerMobile = async (fileType) => {
        let res = await pickImageAsync(fileType);

        if (res && !res.canceled) {
            setFile(res);
            setUploading(10);
            getPresignedUrlAndUpload([res], fileType, sessionId, setUploading).then(() => {
                setUploading(false);
                onFileUploadCallback(res);
            });
        }
    };



    const onFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            setUploading(10);
            getPresignedUrlAndUpload([file], fileType, sessionId, setUploading).then(() => {
                setUploading(false);
                onFileUploadCallback(file);
            });
        }
    };

    const objectPlaceholder = (fileType === 'image') ? "Upload a picture of your face" : "Upload video";
    const fileMimeType = fileType === 'video' ? 'video/*, image/gif' : 'image/*';

    return (
        <View style={styles.container}>
            <View style={styles.contentPreview}>
                {uploading ? (
                    <Progress.Bar progress={uploading / 100} style={styles.progressBar} indeterminate={true} />
                ) : (
                    <Text style={styles.textStyleUploaders}>{objectPlaceholder}</Text>
                )}
            </View>
            {!uploading && (
                Platform.OS === 'web' ? (
                    <>
                        <Button onPress={handleClick} style={styles.button}>
                            <View style={{ flexDirection: 'column', justifyContent: 'center', height: '100%', padding: scale(1) }}>
                                <AntDesign name="upload" size={isDesktopWeb ? scale(8) : scale(24)} color="white" />
                            </View>
                        </Button>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={onFileUpload}
                            accept={fileMimeType}
                            style={{ display: 'none' }}
                            multiple={false}
                        />
                    </>
                ) : (
                    <View style={styles.cameraButtonsMobile}>
                        <Button onPress={() => onCameraMobile(fileType)} style={styles.button}>
                            {fileType === 'image' ? (
                                <Fontisto name="camera" size={scale(20)} color="white" />
                            ) : (
                                <Ionicons name="videocam" size={scale(20)} color="white" />
                            )}
                        </Button>
                        <Button onPress={() => onImagePickerMobile(fileType)} style={styles.button}>
                            <Icon name="image" size={24} color="white" />
                        </Button>
                    </View>
                )
            )}
        </View>
    );
}

const styles = ScaledSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    contentPreview: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        marginTop: Platform.OS === 'web' ? '10@mvs' : '20@mvs',
        margin: Platform.OS === 'web' ? 'auto' : '2@ms',
        backgroundColor: '#282C34',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8@mvs',
    },
    cameraButtonsMobile: {
        flexDirection: 'row',
        margin: '10@ms',
    },
    closeButton: {
        marginBottom: '5@mvs',
        // backgroundColor: '#DC3545',
        color: 'black',
    },
    preview: {
        width: isDesktopWeb ? '70@ms' : '80@ms',
        aspectRatio: 1,
    },
    videoFrame: {
        width: isDesktopWeb ? '200@s' : '80@s',
        height: 'auto',
        // aspectRatio: 1,
        alignSelf: 'center',
    },
    textStyleUploaders: {
        fontSize: isDesktopWeb ? '10@ms' : '20@ms',
    },
    progressBar: {

    }
});

export default FileUploader;