import 'react-native-get-random-values';

import * as ImagePicker from 'expo-image-picker';
import * as Progress from 'react-native-progress';

import { ActivityIndicator, FlatList, Image, Platform, Pressable, ScrollView, Text, View } from 'react-native';
import React, { useEffect } from 'react';
import { isDesktopWeb, valueFor } from '../PlatformTools';
import { scale, verticalScale } from 'react-native-size-matters';

import { AntDesign } from '@expo/vector-icons';
import Constants from 'expo-constants';
import CustomButton from '../CustomButton';
import { Entypo } from '@expo/vector-icons';
import Ionicons from '@expo/vector-icons/Ionicons';
import { MaterialIcons } from '@expo/vector-icons';
import { ScaledSheet } from 'react-native-size-matters';
import asyncStorage from '../../utils/storage';
import avatarState from '../../atoms/avatarState';
import { getPresignedUrlAndUpload } from '../../utils/s3';
import jobsState from '../../atoms/jobsState';
import { startAvatarTrainingJob } from '../../utils/jobs';
import { useRecoilState } from 'recoil';
import userState from '../../atoms/userState';
import { v4 as uuidv4 } from 'uuid';

export default function ImageUploadStep({ navigation }) {
    const PRICE_PER_GENERATION = 200;

    const [user, setUser] = useRecoilState(userState);
    const [avatarProps, setAvatarProps] = useRecoilState(avatarState);
    const [error, setError] = React.useState(null);
    const [uploading, setUploading] = React.useState(null);
    const [isJobStarted, setIsJobStarted] = React.useState(false);
    const [jobs, setJobs] = useRecoilState(jobsState);


    const minNumberOfPhotos = 6;

    const goodExamples = [
        { id: '1', source: require('../../assets/avatars/examples/good1.png') },
        { id: '2', source: require('../../assets/avatars/examples/good2.png') },
        { id: '3', source: require('../../assets/avatars/examples/good3.png') },
        { id: '4', source: require('../../assets/avatars/examples/good4.png') },
        { id: '5', source: require('../../assets/avatars/examples/good5.png') },
        { id: '6', source: require('../../assets/avatars/examples/good6.png') },
    ];

    const badExamples = [
        { id: '1', source: require('../../assets/avatars/examples/bad1.png') },
        { id: '2', source: require('../../assets/avatars/examples/bad2.png') },
        { id: '3', source: require('../../assets/avatars/examples/bad3.png') },
        { id: '4', source: require('../../assets/avatars/examples/bad4.png') },
        { id: '5', source: require('../../assets/avatars/examples/bad5.png') },
        { id: '6', source: require('../../assets/avatars/examples/bad6.png') },
    ];
    const fileType = "image";


    useEffect(() => {
        // Create a new Job ID, but make sure
        // the avatarProps were loaded from asyncStorage first.
        if (!avatarProps.loading) {
            const jobId = uuidv4();
            setAvatarProps(prevProps => {
                if (prevProps.job.id != null) {
                    return prevProps;
                }
                return {
                    ...prevProps,
                    job: {
                        id: jobId,
                    },
                };
            });
        }
    }, [avatarProps.loading]);

    useEffect(() => {
    }, [avatarProps.job.id]);

    const onFileUploadCallback = (data) => {
    }

    if (!user || !user.username) {
        return <View><Text>Still loading...</Text></View>
    }

    function renderItem(iconType) {
        return ({ item }) => {
            return (
                <View style={styles.imageContainer} key={item.id}>
                    <Image
                        source={item.source}
                        style={styles.image}
                    />
                    {iconType === "bad" ?
                        <Entypo name="squared-cross" size={styles.imageIcon.size} color="red" style={styles.icon} />
                        : <Ionicons name="checkbox" size={styles.imageIcon.size} color="green" style={styles.icon} />
                    }
                </View>
            );
        }
    }

    const pickImageAsync = async (fileType) => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: fileType === 'video' ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images,
            // allowsEditing: true,
            allowsMultipleSelection: true,
            quality: 1,
        });

        const fileMimeType = fileType === 'video' ? 'video/mp4' : 'image/jpeg';
        const files = result.assets.map(file => {
            const filename = file.uri.split('/').pop();
            return {
                name: filename,
                type: fileMimeType,
                uri: file.uri,
            };
        });

        return files;
    };


    const onImagePickerMobile = async (fileType) => {
        setUploading(10);
        let res = await pickImageAsync(fileType);

        if (res && !res.canceled) {
            const s3key = user.username + '/avatars/' + avatarProps.job.id + '/train';
            getPresignedUrlAndUpload(res, fileType, s3key, setUploading, onFileUploadCallback).then(
                () => {
                    setAvatarProps({ ...avatarProps, files: [...avatarProps.files, ...res] });
                    setUploading(false);
                });
        }
    };

    const hiddenFileInput = React.useRef(null);
    const handleUploadClick = event => {
        if (Platform.OS === 'web') {
            hiddenFileInput.current.click();
        } else {
            onImagePickerMobile("image");
        }
    };

    const onFileUploadClick = async (event) => {
        const files = Array.from(event.target.files)

        if (files) {
            setUploading(10);
            const s3key = user.username + '/avatars/' + avatarProps.job.id + '/train';
            getPresignedUrlAndUpload(files, fileType, s3key, setUploading, onFileUploadCallback).then(
                () => {
                    const filenames = files.map(file => file.name);
                    setAvatarProps({ ...avatarProps, files: [...avatarProps.files, ...filenames] });
                    setUploading(false);
                });
        }
    };

    const handleGenerationClick = () => {
        setIsJobStarted(true);
        if (user.balance < PRICE_PER_GENERATION) {
            navigation.navigate('PaymentScreen', { jobId: avatarProps.job.id, price: PRICE_PER_GENERATION });
        } else {
            startAvatarTrainingJob(user, avatarProps, setAvatarProps).then(job => {
                if (job.error) {
                    console.log("Error starting job: ", job.error);
                    setError(job.error);
                } else {
                    console.log("Job started: ", job);
                    setUser({ ...user, balance: user.balance - PRICE_PER_GENERATION });
                    navigation.navigate('JobScreen', { jobId: job.id, price: PRICE_PER_GENERATION });
                }
                setIsJobStarted(false);
            }).catch(error => {
                console.log("Error starting job: ", error);
                setError(error);
                setIsJobStarted(false);
            })
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.requirements}>
                <View style={styles.requirement}>
                    <View style={styles.requirementsTitleView}>
                        <Ionicons name="checkmark-circle" size={styles.titleIcon.size} color="green" style={styles.icons} />
                        <Text style={styles.requirementsTitle}>Good examples</Text>
                    </View>
                    <Text style={styles.requirementsText}>
                        Selfies or portraits, the same person in all photos, different backgrounds, different facial expressions
                    </Text>
                    <View style={styles.gallery}>
                        <FlatList
                            style={{ width: isDesktopWeb ? scale(215) : 'auto' }}
                            data={goodExamples}
                            renderItem={renderItem("good")}
                            keyExtractor={item => item.id}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            windowSize={1}
                            nestedScrollEnabled={true}
                        />

                    </View>
                </View>
                <View style={styles.requirement}>
                    <View style={styles.requirementsTitleView}>
                        <Ionicons name="md-warning" size={styles.titleIcon.size} color="#ED2647" style={styles.icons} />
                        <Text style={styles.requirementsTitle}>Bad examples</Text>
                    </View>

                    <Text style={styles.requirementsText}>Group of full-body photos, burst shots, covered faces, child photos, nudity</Text>
                    <View style={styles.gallery}>
                        <FlatList
                            style={{ width: isDesktopWeb ? scale(215) : 'auto' }}
                            data={badExamples}
                            renderItem={renderItem("bad")}
                            keyExtractor={item => item.id}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            windowSize={1}
                        />
                    </View>
                </View>
            </View>
            <View style={styles.requirementsTextView}>
                <Text style={styles.requirementsText}>
                    The clearer and sharper your uploaded photos are, the better and more magical the AI-generated results will be.
                </Text>
            </View>


            <View style={styles.uploadSection}>
                <View style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    {Platform.OS === 'web' && (
                        <input
                            type="file"
                            multiple={true}
                            ref={hiddenFileInput}
                            onChange={onFileUploadClick}
                            accept={`image/*`}
                            style={{ display: 'none' }}
                        />
                    )}
                    <View style={{ padding: scale(5), alignItems: 'center', justifyContent: 'center', borderRadius: scale(5) }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={styles.uploadCounter}><Text style={{ fontWeight: 'bold' }}>{avatarProps?.files?.length || 0}</Text> photos uploaded</Text>
                            <MaterialIcons name="delete" size={isDesktopWeb ? scale(8) : scale(15)} onPress={() => setAvatarProps({ ...avatarProps, files: [] })} color="gray" />
                        </View>
                        <View style={uploading ? styles.progressBar : styles.uploadButton}>
                            {uploading ? (
                                <Progress.Bar progress={uploading / 100} style={styles.progressBar} indeterminate={true} />
                            ) : (
                                <Pressable onPress={handleUploadClick}>
                                    {minNumberOfPhotos > avatarProps?.files?.length ? (
                                        <Text style={styles.uploadButtonText}>Upload at least <Text style={{ fontWeight: 'bold' }}>{minNumberOfPhotos - avatarProps?.files?.length}</Text> more photos</Text>
                                    ) : (
                                        <Text style={styles.uploadButtonText}>Upload more photos (Optional)</Text>
                                    )}
                                </Pressable>
                            )}
                        </View>
                    </View>
                    {error && (
                        <View style={styles.errorBox}>
                            <Text style={styles.text}>{error}</Text>
                        </View>
                    )}
                    {!isJobStarted ? (
                        <CustomButton
                            label="Create avatars"
                            disabled={minNumberOfPhotos > avatarProps.files.length}
                            containerStyle={{ marginTop: isDesktopWeb ? verticalScale(10) : verticalScale(10) }}
                            labelStyle={{ fontSize: isDesktopWeb ? scale(6) : scale(20) }}
                            onPress={handleGenerationClick} />
                    ) : (
                        <ActivityIndicator size={scale(20)} style={{ marginTop: verticalScale(10) }} />
                    )}
                </View>
            </View>
        </View >
    )
}

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    requirements: {
        flex: valueFor({ web: 5, ios: 1.4, android: 2 }),
        marginHorizontal: isDesktopWeb ? '25@ms' : '12@ms',
    },
    requirement: {
        flex: 1,
    },
    requirementsTitleView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '2@mvs',
    },
    requirementsTitle: {
        fontWeight: 'bold',
        fontSize: isDesktopWeb ? '10@mvs' : '14@mvs',
        // lineHeight: isDesktopWeb ? scale(10) : scale(22),
    },
    requirementsTextView: {
        marginHorizontal: isDesktopWeb ? '25@ms' : '12@ms',
    },
    requirementsText: {
        fontSize: isDesktopWeb ? '10@mvs' : '12@mvs',
        color: 'black'
    },
    icons: {
        marginRight: isDesktopWeb ? '2@ms' : '3@ms',
    },
    titleIcon: {
        size: isDesktopWeb ? '13@mvs' : '20@mvs',
    },
    gallery: {
        alignItems: 'center',
    },
    image: {
        width: valueFor({ web: '55@ms', ios: '80@ms', android: '80@ms' }),
        height: isDesktopWeb ? '55@mvs' : '80@mvs',
        resizeMode: 'cover',
        borderRadius: 10,
    },
    imageContainer: {
        width: isDesktopWeb ? '55@ms' : '80@ms',
        height: isDesktopWeb ? '55@mvs' : '80@mvs',
        marginTop: '10@mvs',
        position: 'relative',
        marginRight: isDesktopWeb ? '5@mvs' : '10@mvs',
    },
    icon: {
        position: 'absolute',
        bottom: isDesktopWeb ? '-2@mvs' : 0,
        right: isDesktopWeb ? '-1@ms' : 0,
        zIndex: 1,
    },
    imageIcon: {
        size: isDesktopWeb ? '15@ms' : '25@ms',
    },
    uploadSection: {
        flex: isDesktopWeb ? 4 : 1,
        marginHorizontal: isDesktopWeb ? '20@ms' : '12@ms',
        marginTop: isDesktopWeb ? '10@mvs' : '10@mvs',
        alignItems: 'center',
    },
    progressBar: {
        // marginTop: isDesktopWeb ? '10@mvs' : '20@mvs',
    },
    uploadCounter: {
        fontSize: isDesktopWeb ? '10@mvs' : '13@mvs',
        color: 'gray',
        margin: '2@mvs'
    },
    uploadButton: {
        alignItems: 'center',
        backgroundColor: '#1167b1',
        borderRadius: "6@mvs",
        paddingVertical: "10@mvs",
        paddingHorizontal: "10@ms"
    },
    uploadButtonText: {
        color: 'white',
        fontSize: isDesktopWeb ? '12@mvs' : '15@mvs',
    },
    errorBox: {
        marginBottom: '20@mvs',
        padding: '10@mvs',
        borderRadius: 10,
        fontSize: '10@mvs',
        backgroundColor: '#FF9494',
    },
    text: {
        fontSize: isDesktopWeb ? '8@mvs' : '10@mvs',
    }

});

