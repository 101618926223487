import { Text, View } from 'react-native';
import { isDesktopWeb, valueFor } from '../PlatformTools';
import { scale, verticalScale } from 'react-native-size-matters';

import CustomButton from '../CustomButton';
import CustomVideo from '../CustomVideo';
import React from 'react';
import SaveFilesButton from '../SaveFilesButton';
import { ScaledSheet } from 'react-native-size-matters';
import { Storage } from 'aws-amplify';
import faceswapJobState from '../../atoms/faceswapJobState';
import { timeAgo } from '../../utils/formatters';
import { useRecoilState } from 'recoil';
import userState from '../../atoms/userState';

export default function FaceswapResultScreen({ job, navigation, goBack }) {
    const { user, setUser } = useRecoilState(userState);
    const [faceswapJob, setFaceswapJob] = useRecoilState(faceswapJobState);

    const [videoUrl, setVideoUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const fetchPresignedUrl = async () => {
        setLoading(true);
        try {
            const url = await Storage.get("faceswap/" + job.id + "/" + "result.mp4", { level: "private", expires: 3600 });
            setVideoUrl(url);
        } catch (error) {
            alert("Failed to fetch presigned URL:" + error + " stack:" + error.stack);
        } finally {
            setLoading(false);
        }
    };

    const handleVideoError = () => {
        // If the video fails to load, try fetching a new presigned URL
        fetchPresignedUrl();
    };

    React.useEffect(() => {
        fetchPresignedUrl();
    }, []);


    const resetAndGoBack = () => {
        setFaceswapJob(faceswapJob, {});
        navigation.navigate("FaceswapIntro");
    }


    return (
        <View style={styles.container}>
            <View style={styles.content}>

                <View style={styles.headerControls}>
                    {goBack && (
                        <CustomButton
                            label="Back to projects"
                            onPress={goBack}
                            containerStyle={styles.topNavigationButton}
                            labelStyle={{ fontSize: isDesktopWeb ? scale(6) : scale(16) }}
                        />
                    )}
                    <SaveFilesButton
                        sourceList={["faceswap/" + job.id + "/result.mp4"]}
                        styleButton={styles.downloadButton}
                        styleText={styles.button}
                    />

                </View>
                <View style={{ alignItems: 'center' }}>
                    {job?.createdAt && (
                        <Text style={[styles.headerText]}>Created {timeAgo(job?.createdAt)}</Text>
                    )}
                    <CustomVideo source={videoUrl} style={styles.videoFrame} autoPlay={true} muted={true} controls={false} loop={true} onError={handleVideoError} />
                </View>

                <View style={styles.bottomControls}>
                    <CustomButton
                        label="Create new video"
                        containerStyle={{ marginTop: isDesktopWeb ? verticalScale(10) : verticalScale(30) }}
                        labelStyle={{ fontSize: isDesktopWeb ? scale(6) : scale(16) }}
                        onPress={resetAndGoBack} />
                </View>
            </View>
        </View>
    );
};

const styles = ScaledSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        overflow: 'auto',
        scrollbarWidth: 'none',  // for Firefox
        '&::-webkit-scrollbar': {
            display: 'none'  // for Chrome, Safari, Edge
        },
    },
    headerControls: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: isDesktopWeb ? '25@mvs' : 0,
        marginTop: valueFor({ web: 0, native: '20@mvs' })
    },
    headerImage: {
        // marginTop: '1@mvs',
        width: '100%',
        height: isDesktopWeb ? '210@mvs' : '220@mvs',
        resizeMode: 'cover',
    },
    content: {
        flex: 1,
        margin: '25@ms',
    },
    headerText: {
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
        color: 'grey',
    },
    headerTextLine2: {
        marginTop: isDesktopWeb ? '10@mvs' : '10@mvs',
    },
    balanceView: {
        marginVertical: '15@mvs',
        backgroundColor: '#FFCC5C',
        padding: '10@ms',
        borderRadius: '5@ms',
    },
    balanceText: {
        fontSize: isDesktopWeb ? '10@ms' : '17@ms',
    },
    solutionText: {
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
    },
    bottomControls: {
        alignItems: 'center',
    },
    topNavigationButton: {
        // marginTop: isDesktopWeb ? '10@mvs' : '5@mvs',
        width: isDesktopWeb ? '100@ms' : '160@ms',
        height: isDesktopWeb ? '25@mvs' : '45@ms',
    },
    videoFrame: {
        width: isDesktopWeb ? '350@ms' : '320@ms',
        maxHeight: isDesktopWeb ? '380@mvs' : '380@mvs',
        borderRadius: '10@ms',
        alignItems: 'center'
    },
    button: {
        color: 'white',
        fontSize: isDesktopWeb ? '10@mvs' : '20@ms',
        // lineHeight: isDesktopWeb ? 'auto' : '20@mvs'
    },
    downloadButton: {
        // marginTop: isDesktopWeb ? '20@mvs' : '22@mvs',
        // alignSelf: 'flex-end'
    },
});