import { Linking, Text } from 'react-native';

import Constants from 'expo-constants';
import CustomButton from './CustomButton';
import { ScaledSheet } from 'react-native-size-matters';
import { isDesktopWeb } from './PlatformTools';
import { useRecoilState } from 'recoil';
import userState from '../atoms/userState';

export default function StripeBuyButton({ onPress, containerStyle, buttonStyle, labelStyle, label = "Buy credits!" }) {
    const [user, setUser] = useRecoilState(userState);

    const handleBuyButtonClick = () => {
        const paymentLink = `https://buy.stripe.com/${Constants.expoConfig.extra.stripePaymentLinkID}?client_reference_id=${user.username}&email=${user.email}`;
        console.log("paymentLink: " + paymentLink);
        Linking.openURL(paymentLink);
        if (onPress)
            onPress();
    }

    return (
        <CustomButton label={label} onPress={handleBuyButtonClick} containerStyle={containerStyle} buttonStyle={buttonStyle} labelStyle={labelStyle} />
    );
}

const styles = ScaledSheet.create({
    buyButton: {
        margin: '10@mvs',
        width: isDesktopWeb ? '100@ms' : '120@ms',
        height: isDesktopWeb ? '25@mvs' : '40@ms',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#282C34',
        marginTop: '40@mvs'
    },
    buyButtonLabel: {
        color: 'white',
        fontSize: isDesktopWeb ? '10@ms' : '15@ms',
        lineHeight: '20@mvs'
    },
});

